import React, { useState } from "react";
import SocialIcons from "./SocialIcons";
import { FaRegCopy } from "react-icons/fa6";
import { IoCheckmarkDone } from "react-icons/io5";

const ShareModal = ({ setShow, item, Share }) => {
  const [copy, setCopy] = useState(false);
  setTimeout(() => {
    setCopy(false);
  }, 3000);

  return (
    <div class="fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[10000000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
      <div class="w-full max-w-md bg-black !text-white border-[1px] border-redColor shadow-md shadow-redColor rounded-md p-6 relative">
        <div class="flex items-center pb-3 border-b ">
          <h3 class="text-xl font-bold flex-1 text-white">Share Modal</h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-3.5 cursor-pointer shrink-0 fill-white hover:fill-red-500"
            viewBox="0 0 320.591 320.591"
            onClick={() => setShow(false)}
          >
            <path
              d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
              data-original="#000000"
            ></path>
            <path
              d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
              data-original="#000000"
            ></path>
          </svg>
        </div>
        <div class="my-8">
          <h6 class="text-base text-white">Share this link via</h6>
          <div class="space-x-6 mt-6 text-center">
            {/* --------- social icons  */}
            <div>
              <SocialIcons item={item} Share={Share} />
            </div>
          </div>
        </div>
        <div>
          <h6 class="text-base text-white]">Or copy link</h6>
          <div class="w-full rounded overflow-hidden border flex items-center mt-6">
            <input
              class="text-sm text-gray-400 flex-1 ml-4 border-none outline-none bg-transparent"
              // value={`http://instapass.shop/tickets/group.details/${item.id}`}
              value={`http://instapass.shop/tickets/group.details/${item.id}/${item?.venue?.location}/${item?.occurs_at_local}/${item?.occurs_at_local}/${item?.name}`}
              readOnly
              onChange={() => { }}
            />
            {!copy ? (
              <button
                class="bg-color4 px-3 py-3 text-sm text-white "
                onClick={() => {
                  navigator.clipboard.writeText(
                    `http://instapass.shop/tickets/group.details/${item.id}/${item?.venue?.location}/${item?.occurs_at_local}/${item?.occurs_at_local}/${item?.name}`
                  );
                  setCopy(true);
                }}
              >
                <FaRegCopy className="text-[25px]" />
              </button>
            ) : (
              <button
                class="bg-color4 px-3 py-3 text-sm text-white "
              >
                <IoCheckmarkDone className="text-[25px]" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
