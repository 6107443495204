import React, { useState, useEffect } from "react";
import dropin from "braintree-web-drop-in";
import { ApiURL } from "../../setting/GlobleVariable";
import toast from "react-hot-toast";
import { Loading } from "notiflix";
import Spinner from "../Loading/Spinner";
import { useSelector } from "react-redux";

const PayPalPayment = ({ show, onPaymentCompleted, totalamount }) => {
  const [braintreeInstance, setBraintreeInstance] = useState(null);
  const [isloading, setIsloading] = useState(false);
  const [loading, setloading] = useState(false);
  const isloadingcreateOrder = useSelector((state) => state.events.isLoading);


  const singleCouponCode = useSelector(
    (state) => state.couponcode.singleCouponCode
  );

  useEffect(() => {
    if (isloading === true || isloadingcreateOrder === true) {
      Loading.standard("Loading Plaese Wait");
    } else {
      Loading.remove();
    }
  }, [isloading , isloadingcreateOrder]);


  useEffect(() => {
    const initializeBraintree = async () => {
      if (show === true) {
        try {
          setloading(true);
          const response = await fetch(`${ApiURL}/payment/generateClient`, {
            method: "GET",
            headers: {
              token:  localStorage.getItem("instapass_user_token"),
            },
          });
          const data = await response.json();
          if (response.status === 400 || response.status === 500) {
            return toast.error("Plaese Login to continue");
          }

          const { clientToken } = data;

          var instance = await dropin.create({
            authorization: clientToken,
            container: "#braintree-drop-in-div",
            card: false,
            paypal: {
              flow: "checkout",
              amount:
                singleCouponCode && singleCouponCode?.discount
                  ? totalamount - singleCouponCode?.discount <= 0
                    ? 0
                    : totalamount - singleCouponCode?.discount
                  : totalamount,
              currency: "USD",
              enableShippingAddress: false,
              enableBillingAddress: false,
              shippingAddressEditable: false,
              payeeEmail: "your_merchant_account_email@example.com", 
              displayName: "Your Merchant Name", 
              buttonStyle: {
                color: "gold", 
                shape: "rect",
                size: "medium",
              },
            },
          });
          setloading(false);
          setBraintreeInstance(instance);

          instance.on("paymentMethodRequestable", (event) => {
            instance.requestPaymentMethod((err, payload) => {
              if (err) {
                console.error(err, "error from payap");
                return;
              }
              const nonce = payload.nonce;
              // console.log(nonce, "nonace from paypal");
              handlePayment(nonce);
            });
          });
        } catch (error) {
          console.error("Error initializing Braintree:", error);
        }
      }
    };

    if (show === true) {
      initializeBraintree();
    } else {
      if (braintreeInstance) {
        braintreeInstance.teardown().then(() => setBraintreeInstance(null));
      }
    }

    return () => {
      if (braintreeInstance) {
        braintreeInstance.teardown().then(() => setBraintreeInstance(null));
      }
    };
  }, [show]);

  const handlePayment = async (nonce) => {
    // Handle payment logic using the provided nonce
    try {
      setIsloading(true);
      const response = await fetch(`${ApiURL}/payment/process/payment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          payment_method_nonce: nonce,
          amount: totalamount,
        }),
      });
      setIsloading(false);

      if (response.status === 200) {
        // console.log("Payment processed successfully");
        toast.success(`Payment completed`);
        onPaymentCompleted();
      } else {
        const responseData = await response.json();
        console.error("Error processing payment:", responseData.message);
        toast.error("Error processing payment. Please try again.");

      }
    } catch (error) {
      console.error("Error processing payment:", error);
      toast.error("Error processing payment. Please try again.");

    }
  };

  return (
    <div style={{ display: `${show ? "block" : "none"}` }}>
      <div id="braintree-drop-in-div" />
      {loading && (
        <div className="w-full flex justify-center place-items-center py-2 px-2">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default PayPalPayment;
