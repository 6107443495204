import { Loading } from "notiflix";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetOrderDeatils,
  UpateOrderStstus,
} from "../../../../redux/actions/Admin/AdminOrders";

const NewOrders = ({ setActive }) => {
  const OrderData = useSelector((state) => state.AdminOrders.OrderData)
    ?.slice(-5)
    ?.reverse();
  const adminloading = useSelector((state) => state.user.adminloading);
  const [Orderstatus, setOrderstatus] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (adminloading === true) {
      Loading.standard("Loading Plaese Wait", {
        svgColor: "#CD292E",
      });
    } else {
      Loading.remove();
    }
  }, [adminloading]);

  return (
    <div className="py-5 w-[100%]">
      <h2 className="text-[30px] ">New Order</h2>

      {/* ---  */}

      <div className="max-w-[1000px] xl:max-w-[100%]">
        <div className=" shadow-md sm:rounded-lg order_table">
          <table className="w-full  text-sm text-left rtl:text-right border-[1px] border-color3 rounded-sm  dark:text-gray-400 !bg-color1 !text-color2">
            <thead className="text-xs text-gray-700 uppercase bg-color4 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 h_text">
                  OrderId
                </th>
                <th scope="col" className="px-6 py-3 h_text">
                  Ticket Type
                </th>
                <th scope="col" className="px-6 py-3 h_text">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 h_text">
                  Total Tickets
                </th>
                <th scope="col" className="px-6 py-3 h_text">
                  Toatl Amount
                </th>

                <th scope="col" className="px-6 py-3 h_text">
                  Payment Method
                </th>

                <th scope="col" className="px-6 py-3 h_text">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {OrderData?.map((item, index) => {
                return (
                  <tr
                    className={`!bg-color1  !text-color2 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600
                  
                  ${index % 2 !== 0 ? "!bg-slate-900" : "bg-color1"}
                  `}
                    key={index}
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium whitespace-nowrap dark:text-white"
                    >
                      #{item?._id?.slice(0, 5)}
                    </th>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium whitespace-nowrap dark:text-white"
                    >
                      {item?.type}
                      name
                    </th>
                    <td className="px-6 py-4">{item?.status}</td>
                    <td className="px-6 py-4">{item?.items?.length}</td>
                    <td className="px-6 py-4">${item?.totalAmount}</td>
                    <td className="px-6 py-4">{item?.payments}</td>
                    <td className="flex items-center px-6 py-4  gap-3">
                      <a
                        href="#"
                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                        onClick={() => {
                          setActive(10);
                          dispatch(GetOrderDeatils(item?._id));
                        }}
                      >
                        Details
                      </a>
                      <button
                        className="border-[1px] px-2 rounded-md text-[14px] py-1 cursor-pointer"
                        onClick={() => {
                          setShowModal(true);
                          setOrderId(item?._id);
                        }}
                      >
                        Update Status
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {showModal && (
        <div className="fixed z-50 inset-0 flex items-center justify-center overflow-hidden">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <div className="bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="w-[100%] flex justify-between place-items-center">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Update Role
                </h3>
                <p
                  className="text-black text-[20px] cursor-pointer"
                  onClick={() => setShowModal(false)}
                >
                  X
                </p>
              </div>
              <div className="mt-2">
                <select
                  className="text-black border-[2px] w-[100%] my-3 py-1 px-2"
                  onChange={(e) => setOrderstatus(e.target.value)}
                  value={Orderstatus}
                >
                  <option value="">Select order status</option>
                  <option value="Pending">Pending</option>
                  <option value="Refund">Refund</option>
                  <option value="Refund Reject">Refund Reject</option>
                  <option value="Deliverd">Deliverd</option>
                </select>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={async () => {
                  await dispatch(UpateOrderStstus(orderId, Orderstatus));
                  setShowModal(false);
                  setOrderId(null);
                  setOrderstatus("");
                }}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewOrders;
