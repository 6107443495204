import React, { useEffect, useRef } from "react";
import HeroImage from "../../../assets/BlogsImages/blogimage2.png";
import feedImage1 from "../../../assets/BlogsImages/blog1image.png";
import feedsimage2 from "../../../assets/BlogsImages/blogimage3.png";
import { FaArrowRightLong } from "react-icons/fa6";
import "./Feeds.css";
import FeedContent from "./FeedsContent";
import FeedsContent2 from "./FeedsContent2";
import FeedsContent3 from "./FeedsContent3";
import FeedsContent4 from "./FeedsContent4";
import { NavLink } from "react-router-dom";
import newsFeedImage from "../../../assets/newsfeed.png";

const Feeds = ({ id }) => {
  const affiliateBannerRef = useRef(null)
  const depen = document?.getElementById("affiliateBanner02")?.innerHTML;
  useEffect(() => {
    const affiliateBannerHTML = document?.getElementById("affiliateBanner02")?.innerHTML;
    affiliateBannerRef.current.innerHTML = affiliateBannerHTML;
    affiliateBannerRef.current.style.display = "block";

  }, [affiliateBannerRef, depen])
  return (
    <div className="max-w-[2000px] mx-auto my-[50px] px-[100px] bg-color1 text-color2 feeds_parent">
      <h2 className="feeds_heading">
        {id == 1
          ? "Discover 7 Of The Most Historic Concert Hall Designs In The World"
          : id == 2
            ? "How To Sell Concert Tickets and Make Money"
            : id == 3
              ? "All you need to know about Ticket Market Place"
              : id == 4
                ? "What You Should Do To Organize A Successful Concert Event"
                : null}
      </h2>
      <div className="Feeds_hero">
        <img
          src={
            newsFeedImage
            // "https://images.pexels.com/photos/1537636/pexels-photo-1537636.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          }
          alt=""
        />
      </div>
      {/* ===== content area  */}
      <div className="feeds_content">
        {/* ---- left  */}
        <div className="feeds_content_left">
          {id == 1 && <FeedContent />}
          {id == 2 && <FeedsContent2 />}
          {id == 3 && <FeedsContent3 />}
          {id == 4 && <FeedsContent4 />}
        </div>
        {/* --- right  */}
        <div className="feeds_content_right">
          <NavLink to={"/newsfeed/2"} className="feeds_content_right_box">
            <img src={feedImage1} alt="" />
            <div className="feeds_content_right_box_content">
              <h2>How To Sell Concert Tickets and Make Money</h2>
              <p>
                If you are having problems selling tickets, it may be that you
                are like most salesmen who are not getting in...
              </p>
              <button>
                Read More{" "}
                <span>
                  <FaArrowRightLong />
                </span>
              </button>
            </div>
          </NavLink>
          <NavLink to={"/newsfeed/3"} className="feeds_content_right_box">
            <img src={feedsimage2} alt="" />
            <div className="feeds_content_right_box_content">
              <h2>All you need to know about Ticket Market Place</h2>
              <p>
                Do you have a concert coming up, but feel stuck on how to sell
                your concert tickets? Fortunately, whether you're selling...
              </p>
              <button>
                Read More{" "}
                <span>
                  <FaArrowRightLong />
                </span>
              </button>
            </div>
          </NavLink>
          <NavLink to={"/newsfeed/4"} className="feeds_content_right_box">
            <img src={HeroImage} alt="" />
            <div className="feeds_content_right_box_content">
              <h2>What You Should Do To Organize A Successful Concert Event</h2>
              <p>
                Listening to your favorite band or artist while on the road, is
                not the same as seeing them perform ....
              </p>
              <button>
                Read More{" "}
                <span>
                  <FaArrowRightLong />
                </span>
              </button>
            </div>
          </NavLink>
          <div ref={affiliateBannerRef} className="my-[20px]"></div>
        </div>

      </div>
    </div>
  );
};

export default Feeds;
