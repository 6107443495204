import React from "react";

const FeedsContent2 = () => {
  return (
    <>
      <div>
        <h2>Introduction</h2>
        <p>
        People tend to look down on ticket reselling and the reason is that they often mix up ticket reselling with ticket scalping. Scalping brings up images of shady folks lingering on street corners, selling concert tickets to customers who couldn't obtain one before they are sold out for a very high price
        </p>
        <p>
        Scalping is prohibited in many countries. But, there are legal alternatives to scalping.
        </p>
        <p>
        In this post, we will look at how to legally sell concert tickets, and generate money from them.
        </p>
      </div>
      <div>
        <h2>Ticket Reselling vs. Ticket Scalping</h2>
        <p>
          Ticket reselling is a lawful business that follows all federal, state,
          and municipal regulations. The reseller purchases these tickets from a
          licensed seller who complies with all applicable ticket-selling
          requirements. Having shown that selling concert tickers or ticket
          reselling is legal, how do you sell? legal, how do you sell? legal,
          how do you sell?
        </p>
      </div>
      <div>
        <h2>Simple and Effective Ways to Sell Concert Tickets</h2>
        <h3>1) Sell Tickets on Mobile</h3>
        <p>
          An estimated 1.8 billion consumers bought products online last year.
          For the first quarter of 2019, smartphones were the most popular
          device for digital shopping around the world. One of the most notable
          commerce trends you can explore to enhance your sales this year and
          beyond is the use of mobile for both major and little purchases. So,
          to add to the regular selling of tickets on the ticketing website,
          make sure you have a mobile presence to sell concert tickets, either
          through an app or a mobile-friendly website
        </p>
        <h3>2) Set a good price to sell on resale websites</h3>
        <p>
          As earlier hinted, you can sell concert tickets with resale websites.
          To determine a fair price for your tickets, look at similar listings.
          Afterward, stamp a ticket price that reflects the value of the ticket
          (for instance, use a greater price for closer seats) while also
          encouraging consumers to choose your tickets over others. Look on
          resale websites for other tickets to your performance to know the
          deals you'll be up against, and to position properly. For example, if
          your tickets were originally $500 and other retailers were selling
          similar seats for $400, you should price your tickets around $300-$400
          to stay competitive.
        </p>
        <h3>3) Leverage your speakers’ audience </h3>
        <p>
          This is quite simple to do. How? Get in touch with your speakers
          before the event and ask them to promote their sessions on social
          media. If someone does speaking engagements, they most likely have a
          vast network to tap into for cross-promotion. In addition, you can
          include (tag) the speaker's name in your own social media postings to
          encourage them to share. Then make referral codes available to use
          when advertising. This will help to keep track of the ticket sales
          they generate for you.
        </p>
        <h3>4) Start a referral program</h3>
        <p>
          If you are having problems selling tickets, it may be that you are
          like most salesmen who are not getting in front of enough people. In
          fact, according to a recent survey, 66.7 percent of respondents stated
          they only had 250 or fewer leads in the previous year.
        </p>
        <p>
          And, while there is no hard and fast rule for how many leads are
          required to close a deal on average, it is a numbers game. One of the
          many reasons why referral schemes are so effective at increasing event
          ticket sales is because of more leads.
        </p>
        <p>
          In addition, 92 percent of study respondents said they trusted
          customer referrals from people they knew. This meant that people who
          received personal referrals were 4X more likely to purchase after
          receiving the recommendation, compared to if they discovered it
          organically elsewhere.
        </p>
        <h3>5) Use ads for your social media handles</h3>
        <p>
          Use social media to promote your ticket sales ad. This is a fantastic
          method to broaden the pool of people who might be interested in
          purchasing your tickets including family, friends, and acquaintances.
        </p>
        <p>
          Post a status on Facebook, for example, directing people to your
          ticket listing.
        </p>
        <p>
          But don't forget to provide a link to the website where people can
          purchase the tickets in your post
        </p>
        <h3>6) Use Email drip Campaigns</h3>
        <p>
          On average, companies that use email drip marketing usually receive a
          20% ROI. Customers that are connected to your business over a long
          time are 47 percent more likely to make larger purchases. Hence,
          nurturing leads through a succession of automated and well-timed
          messages is a wonderful long-term approach.
        </p>
        <p>
          After nurturing the prospects, use email drip campaigns to sell out
          the concert ticket faster and more efficiently.
        </p>
        <h3>Conclusion </h3>
        <p>
          Do you have a concert coming up, but feel stuck on how to sell your
          concert tickets? Fortunately, whether you're selling concert tickets
          online or in person, selling concert tickets is not rocket science and
          you can do it too. Examine the tips detailed in this post and use
          them.
        </p>
        <p>Thanks for reading!</p>
      </div>
    </>
  );
};



export default FeedsContent2