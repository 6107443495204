import React, { useEffect, useRef } from 'react'

const VerticalExpediaBanner = () => {
    const affiliateBanner02Ref = useRef(null)
    const depen = document?.getElementById("affiliateBanner02")?.innerHTML;
    useEffect(() => {
      const affiliateBannerHTML = document?.getElementById("affiliateBanner02")?.innerHTML;
      affiliateBanner02Ref.current.innerHTML = affiliateBannerHTML;
      affiliateBanner02Ref.current.style.display = "block";
  
    }, [affiliateBanner02Ref, depen])
  return (
    <div ref={affiliateBanner02Ref} className=" my-[20px] md:block sm:hidden responsivehidden"></div>

  )
}

export default VerticalExpediaBanner
