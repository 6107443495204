import React from "react";
import { useSelector } from "react-redux";
import Spinner from "../../../../Components/Loading/Spinner";

const Hero = ({ active, setActive }) => {
  const OrderData = useSelector((state) => state.AdminOrders.OrderData);
  const AdminUsers = useSelector((state) => state.user.AdminAllUser);
  const IsLoadingOrder = useSelector((state) => state.AdminOrders.OrderLoading);
  const IsLoadingUsers = useSelector((state) => state.user.adminloading);
  const totalSale = OrderData?.reduce((acc ,item)=>{
     return acc = acc + (item?.totalAmount || 0)
  },0)

  return (
    <>
      {/* Statistics: Simple with Action */}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:gap-8 my-5 text-color2">
        {/* Card */}
        <div className="flex flex-col overflow-hidden rounded-lg bg-color3 shadow-sm dark:bg-gray-800 dark:text-gray-100">
          {/* Body */}
          <div className="flex grow items-center justify-between p-5">
            <div className="">
              <dl className="space-y-1">
                <dt className="text-2xl font-bold">
                  {IsLoadingUsers ? <Spinner /> : AdminUsers?.length}
                </dt>
                <dd className="text-sm font-semibold uppercase tracking-wider text-gray-500 dark:text-gray-400">
                  Users
                </dd>
              </dl>
            </div>
            <div className="inline-flex items-center space-x-1 rounded-full bg-color1 px-3 py-2 text-sm font-semibold leading-4 text-color2">
              <svg
                className="hi-solid hi-arrow-circle-up inline-block size-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13a1 1 0 102 0V9.414l1.293 1.293a1 1 0 001.414-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span>7.9%</span>
            </div>
          </div>
          <a
            href="#"
            className="block bg-color4 p-3 text-center text-sm font-medium text-color5   active:bg-gray-50 dark:bg-gray-900/50 dark:text-blue-400 dark:hover:bg-gray-900/75  dark:active:bg-gray-900/50"
            onClick={() => setActive(2)}
          >
            View All Users
          </a>
        </div>
        {/* ---  */}
        <div className="flex flex-col overflow-hidden rounded-lg bg-color3 shadow-sm dark:bg-gray-800 dark:text-gray-100">
          {/* Body */}
          <div className="flex grow items-center justify-between p-5">
            <dl className="space-y-1">
              <dt className="text-2xl font-bold">
                {IsLoadingOrder ? <Spinner /> : OrderData?.length}
              </dt>
              <dd className="text-sm font-semibold uppercase tracking-wider text-gray-500 dark:text-gray-400">
                Orders
              </dd>
            </dl>
            <div className="inline-flex items-center space-x-1 rounded-full bg-color1 px-3 py-2 text-sm font-semibold leading-4 text-color2">
              <svg
                className="hi-solid hi-arrow-circle-up inline-block size-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13a1 1 0 102 0V9.414l1.293 1.293a1 1 0 001.414-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span>7.9%</span>
            </div>
          </div>
          <a
            href="#"
            className="block bg-color4 p-3 text-center text-sm font-medium text-color5   active:bg-gray-50 dark:bg-gray-900/50 dark:text-blue-400 dark:hover:bg-gray-900/75  dark:active:bg-gray-900/50"
            onClick={() => setActive(1)}
          >
            View All Orders
          </a>
        </div>
        {/* -------  */}
        <div className="flex flex-col overflow-hidden rounded-lg bg-color3 shadow-sm dark:bg-gray-800 dark:text-gray-100">
          {/* Body */}
          <div className="flex grow items-center justify-between p-5">
            <dl className="space-y-1">
              <dt className="text-2xl font-bold">{Math.floor(totalSale)}$</dt>
              <dd className="text-sm font-semibold uppercase tracking-wider text-gray-500 dark:text-gray-400">
               Toatl sales amount
              </dd>
            </dl>
            <div className="inline-flex items-center space-x-1 rounded-full bg-color1 px-3 py-2 text-sm font-semibold leading-4 text-color2">
              <svg
                className="hi-solid hi-arrow-circle-up inline-block size-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13a1 1 0 102 0V9.414l1.293 1.293a1 1 0 001.414-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span>7.9%</span>
            </div>
          </div>
          <a
            href="#"
            className="block bg-color4 p-3 text-center text-sm font-medium text-color5   active:bg-gray-50 dark:bg-gray-900/50 dark:text-blue-400 dark:hover:bg-gray-900/75  dark:active:bg-gray-900/50"
          >
            View All Sales
          </a>
        </div>
      </div>
    </>
  );
};

export default Hero;
