import { createReducer } from "@reduxjs/toolkit";
import {
  APPLY_COUPON_ERROR,
  APPLY_COUPON_REQUEST,
  APPLY_COUPON_REQUEST_FAIL,
  APPLY_COUPON_SUCCESS,
  COUPON_CODE_CREATE_ERROR,
  COUPON_CODE_CREATE_REQUEST,
  COUPON_CODE_CREATE_REQUEST_FAIL,
  COUPON_CODE_CREATE_SUCCESS,
} from "../constant/CouponCode";

const initialState = {
  CouponLoading: false,
  AllCouponCode: [],
  singleCouponCode: {},
};

export const CouponReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(COUPON_CODE_CREATE_REQUEST, (state) => {
      state.CouponLoading = true;
    })
    .addCase(COUPON_CODE_CREATE_REQUEST_FAIL, (state) => {
      state.CouponLoading = false;
    })
    .addCase(COUPON_CODE_CREATE_SUCCESS, (state, action) => {
      state.CouponLoading = false;
      state.AllCouponCode = action.payload;
    })
    .addCase(COUPON_CODE_CREATE_ERROR, (state) => {
      state.CouponLoading = false;
    })
    // ---- APPLY COUPON
    .addCase(APPLY_COUPON_REQUEST, (state) => {
      state.CouponLoading = true;
    })
    .addCase(APPLY_COUPON_REQUEST_FAIL, (state) => {
      state.CouponLoading = false;
    })
    .addCase(APPLY_COUPON_SUCCESS, (state, action) => {
      state.CouponLoading = false;
      state.singleCouponCode = action.payload;
    })
    .addCase(APPLY_COUPON_ERROR, (state) => {
      state.CouponLoading = false;
    })
    // ---- REMOVE_COUPON_CODE
    .addCase("REMOVE_COUPON_CODE", (state) => {
      state.singleCouponCode = {};
    });
});
