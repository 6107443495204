import React from "react";
import SignupBox from "./SignupBox";
import LoginAuth from "../PrivateRoutes/LoginAuth";

const Signup = () => {

  return (
    <LoginAuth>
      <div className="bg-color1 my-2 w-full flex justify-center place-items-center py-10 min-h-[100vh]">
         <SignupBox/>
      </div>
    </LoginAuth>
  );
};

export default Signup;
