import React from 'react'
import { CiSearch } from 'react-icons/ci';
import { RxCross1 } from 'react-icons/rx';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { LogoutUserFunc } from '../../../redux/actions/User';
import { VscMenu } from 'react-icons/vsc';

const MobileNavbar = ({showmenu,setshowmenu,searchFunction,search,setSearch ,user ,data , UserAvatar}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div
        className={`${showmenu ? "mobileHeader header_menus" : "header_menus"
          }  bg-white text-black py-2 px-2 overflow-y-auto !z-[999999999999999999]`}
      >
        <RxCross1
          className="cross_burger absolute top-5 right-5 text-[23px] cursor-pointer  hidden"
          onClick={() => setshowmenu(false)}
        />
        <div className="searchbarbox my-4  flex w-full justify-start place-items-center rounded-[10px] gap-2 py-[15px] px-3">
          <CiSearch
            className="text-[20px] cursor-pointer"
            onClick={() => searchFunction()}
          />
          <input
            className="bg-transparent outline-none text-color5 w-full"
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                navigate(`tickets/${search}`);
                setshowmenu(false);
              }
            }}
          />
        </div>
        <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center sidebar_nav ">
          <NavLink
            to={"/"}
            className={`mr-5 hover:text-redColor active:text-[red]`}
            onClick={() => setshowmenu(false)}
          >
            Home
          </NavLink>
          <NavLink
            to={"/tickets"}
            className={`mr-5 hover:text-redColor`}
            onClick={() => setshowmenu(false)}
          >
            All Events
          </NavLink>
          <NavLink
            onClick={() => setshowmenu(false)}
            to="/newsfeed/1"
            className={`mr-5 hover:text-redColor active:text-[red]`}
          >
            News Feed
          </NavLink>
          <NavLink
            to={"/aboutUs"}
            className={`mr-5 hover:text-redColor active:text-[red]`}
            onClick={() => setshowmenu(false)}
          >
            About Us
          </NavLink>
          <NavLink>
            {user && user?.email && (
              <div
                onClick={() => {
                  navigate(`/user/profile`, {
                    state: { data: 1 },
                  });
                }}
              >
                <li className="list-none " onClick={() => setshowmenu(false)}>My tickets</li>
              </div>
            )}
          </NavLink>
          {/* ============ categories accordian  */}

          {
            data?.map((item, index) => {
              return (
                <div className="collapse collapse-plus bg-transparent" key={index}>
                  <input type="radio" name="my-accordion-3" defaultChecked />
                  <div className="collapse-title text-xl font-medium">{item?.name}</div>
                  <div className="collapse-content bg-[#131212]">
                    {
                      item?.subCategory?.length > 0 ? item?.subCategory?.map((elem, index) => {
                        return (
                          <li key={index} className="hover:bg-[#131212] list-none py-2 w-[100%]" onClick={() => setshowmenu(false)}>
                            <NavLink to={`/tickets/category=${elem?.id}`} className="w-[100%] text-[grey] !border-none block">{elem.name}</NavLink>
                          </li>
                        )
                      })
                        :
                        <div className="new_category_box shrink-0 w-[100%] hover:border-b-2 hover:border-b-redColor cursor-pointer">
                          <p className="w-[100%] text-center">No more category found</p>
                        </div>
                    }
                  </div>
                </div>
              )
            })
          }


          {/* ==========================  */}
        </nav>
        {/* =====  */}
        <div className=" hidden my-5  justify-start place-items-center gap-1 w-[100%] login_mobile">
          {Object.keys(user).length === 0 ? (
            <NavLink to="/login" onClick={() => setshowmenu(false)}>
              <button className="w-full items-center  hover:text-color1 py-3 !bg-redColor px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0">
                SignIn / SignUp
              </button>
              <VscMenu className="text-[23px] cursor-pointer  hidden " />
            </NavLink>
          ) : (
            <>
              {user && user?.Avatar ? (
                <>
                  <NavLink
                    to="/user/profile"
                    onClick={() => setshowmenu(false)}
                    className="flex justify-start !place-items-center text-color2 "
                  >
                    <div className="!w-[60px] !h-[60px] flex justify-center place-items-center">
                      {user?.firstName && user?.Avatar && (
                        <img
                          src={UserAvatar}
                          className="!w-[40px] !h-[40px] rounded-full border-[1px] border-redColor"
                          alt=""
                        />
                      )}
                    </div>
                    <h2 className="mx-2 !text-[20px] !text-color2">
                      {user?.firstName}
                    </h2>
                  </NavLink>
                </>
              ) : (
                <NavLink
                  to="/user/profile"
                  className=" flex justify-start !place-items-center text-color2"
                  onClick={() => setshowmenu(false)}
                >
                  <p
                    onClick={() => setshowmenu(false)}
                    className="border-[1px] uppercase border-redColor rounded-full p-3 h-[40px] w-[40px] flex justify-center place-items-center bg-color1 text-[white] font-bold"
                  >
                    {user?.firstName?.slice(0, 1)}
                  </p>
                  <h2 className="mx-2 !text-[20px] !text-color2">
                    {user?.firstName}
                  </h2>
                </NavLink>
              )}
            </>
          )}
          {user && user?.firstName && (
            <button
              className="my-4 py-2 px-3 border-[1px] border-redColor w-full rounded-md"
              onClick={() => dispatch(LogoutUserFunc(navigate))}
            >
              Logout
            </button>
          )}
        </div>
      </div>
  )
}

export default MobileNavbar
