import "./Login.css";
import React from "react";
import Loginbox from "./Loginbox";
import LoginAuth from "../PrivateRoutes/LoginAuth";

const Login = () => {
 

  return (
    <LoginAuth>
      <div className="bg-color1 my-2 w-full flex justify-center place-items-center py-10 min-h-[100vh]">
       <Loginbox/>
      </div>
    </LoginAuth>
  );
};

export default Login;
