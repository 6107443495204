import React from "react";
import { useSelector } from "react-redux";

const OrderDetails = () => {
  const SingleOrderDeatils = useSelector(
    (state) => state.Admin.SingleOrderDeatils
  );
  return (
    <div className="p-[20px]">
      <h2>Order Id #{SingleOrderDeatils?._id}</h2>
      {/* ============ table  */}
      <div class="mt-4  bg-col">
        <div class="w-full overflow-hidden rounded-lg shadow-xs">
          <div class="w-full overflow-x-auto">
            <table class="w-full ">
              <thead>
                <tr class="text-xs font-semibold rounded-t-md tracking-wide text-left text-color2 uppercase border-b border-b-color1 bg-color4">
                  <th class="px-4 py-3">OrderId / TicketId</th>
                  <th class="px-4 py-3">Ticket name</th>
                  <th class="px-4 py-3">Ticket price</th>
                  <th class="px-4 py-3">Ticket splits</th>
                  <th class="px-4 py-3">Ticket type</th>
                  <th class="px-4 py-3">service_fee</th>
                  <th class="px-4 py-3">shiptoEmail</th>
                  <th class="px-4 py-3">shiptoName</th>
                  <th class="px-4 py-3">status</th>
                  <th class="px-4 py-3">tax</th>
                  <th class="px-4 py-3">totalAmount</th>
                </tr>
              </thead>
              <tbody class="bg-color1 divide-y dark:divide-gray-700 dark:bg-gray-800 w-[100%]">
                <tr class="bg-color3 border-b-color1  hover:bg-color1  text-color2">
                  <td class="px-4 py-3 text-sm text-center">
                    {SingleOrderDeatils?.order_Id} /{" "}
                    {SingleOrderDeatils?.item_id}
                  </td>
                  <td class="px-4 py-3">
                    <div class="flex items-center text-sm text-center">
                      <p class="text-[13px]">
                        {SingleOrderDeatils &&
                          SingleOrderDeatils?.items &&
                          SingleOrderDeatils?.items[0]?.name}
                      </p>
                    </div>
                  </td>
                  <td class="px-4 py-3 text-sm text-center">
                    {SingleOrderDeatils &&
                      SingleOrderDeatils?.items &&
                      SingleOrderDeatils?.items[0]?.price}
                  </td>
                  <td class="px-4 py-3 text-sm text-center">
                    {SingleOrderDeatils &&
                      SingleOrderDeatils?.items &&
                      SingleOrderDeatils?.items[0]?.splits}
                  </td>
                  <td class="px-4 py-3 text-xs text-center ">
                    {SingleOrderDeatils && SingleOrderDeatils?.type}
                  </td>
                  <td class="px-4 py-3 text-sm text-center">
                    {SingleOrderDeatils && SingleOrderDeatils?.service_fee}
                  </td>
                  <td class="px-4 py-3 text-sm text-center">
                    {SingleOrderDeatils && SingleOrderDeatils?.shiptoEmail}
                  </td>
                  <td class="px-4 py-3 text-sm text-center">
                    {SingleOrderDeatils && SingleOrderDeatils?.shiptoName}
                  </td>
                  <td class="px-4 py-3 text-sm text-center">
                    {SingleOrderDeatils && SingleOrderDeatils?.status}
                  </td>
                  <td class="px-4 py-3 text-sm text-center">
                    {SingleOrderDeatils && SingleOrderDeatils?.tax}
                  </td>
                  <td class="px-4 py-3 text-sm text-center">
                    {SingleOrderDeatils && SingleOrderDeatils?.totalAmount} $
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* ==== user details  */}
      <div className="my-[50px]">
        <h2>User Details</h2>
        <div class="mt-4  bg-col">
          <div class="w-full overflow-hidden rounded-lg shadow-xs">
            <div class="w-full overflow-x-auto">
              <table class="w-full ">
                <thead>
                  <tr class="text-xs font-semibold rounded-t-md tracking-wide text-left text-color2 uppercase border-b border-b-color1 bg-color4">
                    <th class="px-4 py-3">ClientId</th>
                    <th class="px-4 py-3">Name</th>
                    <th class="px-4 py-3">Email</th>
                    <th class="px-4 py-3">PhoneNumber</th>
                    <th class="px-4 py-3">Role</th>
                  </tr>
                </thead>
                <tbody class="bg-color1 divide-y dark:divide-gray-700 dark:bg-gray-800 w-[100%]">
                  <tr class="bg-color3 border-b-color1  hover:bg-color1  text-color2">
                    <td class="px-4 py-3 text-xs  ">
                      {SingleOrderDeatils &&
                        SingleOrderDeatils?.user?.clientId &&
                        SingleOrderDeatils?.user?.clientId}
                    </td>
                    <td class="px-4 py-3 text-sm ">
                      {SingleOrderDeatils &&
                        SingleOrderDeatils?.user?.firstName &&
                        SingleOrderDeatils?.user?.firstName}{" "}
                      {SingleOrderDeatils &&
                        SingleOrderDeatils?.user?.lastName &&
                        SingleOrderDeatils?.user?.lastName}
                    </td>
                    <td class="px-4 py-3 text-sm ">
                      {SingleOrderDeatils && SingleOrderDeatils?.user?.email}
                    </td>
                    <td class="px-4 py-3 text-sm ">
                      {SingleOrderDeatils &&
                        SingleOrderDeatils?.user?.phoneNumber}
                    </td>
                    <td class="px-4 py-3 text-sm ">
                      {SingleOrderDeatils && SingleOrderDeatils?.user?.role}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
