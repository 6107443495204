import React from "react";
import Hero from "../Components/PagesComponents/Main/Hero";
import ClientTable from "../Components/PagesComponents/Main/ClientTable";
import OrdersChart from "../Components/PagesComponents/Main/OrdersChart";
import NewOrders from "../Components/PagesComponents/Main/NewOrders";

const DashBoard = ({ active, setActive }) => {
  return (
    <div className="px-5 py-5">
      <Hero active={active} setActive={setActive} />
      <OrdersChart />
      <ClientTable />
      <NewOrders setActive={setActive} />
    </div>
  );
};

export default DashBoard;
