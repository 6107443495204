import { ApiURL } from "../../setting/GlobleVariable";
import {
  PRINT_ORDERS_ERROR,
  PRINT_ORDERS_FAIL,
  PRINT_ORDERS_REQUEST,
  PRINT_ORDERS_SUCCESS,
} from "../constant/Orders";
import {
  CREATE_USER_ORDER_FAIL,
  CREATE_USER_ORDER_REQUEST,
  CREATE_USER_ORDER_SUCCESS,
  GET_USER_ORDER_FAIL,
  GET_USER_ORDER_REQUEST,
  GET_USER_ORDER_SUCCESS,
} from "../constant/UserOrder";
import toast from "react-hot-toast";

export const CreateUserOrder =
  (
    totalamount,
    type,
    payments,
    service_fee,
    tax,
    id,
    name,
    price,
    qty,
    order_Id,
    item_id,
    navigate,
    setIsPayment,
    offline,
    fname ,
     email,
     lname
  
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: CREATE_USER_ORDER_REQUEST });
      const res = await fetch(`${ApiURL}/order/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: offline ? "offline" : localStorage.getItem("instapass_user_token"),
        },
        body: JSON.stringify({
          totalamount,
          type,
          payments,
          service_fee,
          tax,
          id,
          name,
          price,
          qty,
          order_Id,
          item_id,
          fname , 
          email,
          lname
          
        }),
      });
      const data = await res.json();
      if (res.status === 400) {
        return console.log(data.message);
      } else {
        dispatch({ type: CREATE_USER_ORDER_SUCCESS });
        setIsPayment(false)
        
        navigate(offline === "offline" ? "/" : `/user/profile`, {
          state: { data: 1 },
        });
      }
    } catch (error) {
      dispatch({ type: CREATE_USER_ORDER_FAIL });
    }
  };

// ---- get all user orders
export const UserOrdersFunc = () => async (dispatch) => {
  try {
    dispatch({ type: GET_USER_ORDER_REQUEST });
    const res = await fetch(`${ApiURL}/order/get/all`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("instapass_user_token"),
      },
    });
    const data = await res.json();
    if (res.status === 400) {
      return console.log(data);
    } else {
      dispatch({ type: GET_USER_ORDER_SUCCESS, payload: data.orders });
    }
  } catch (error) {
    dispatch({ type: GET_USER_ORDER_FAIL });
  }
};

// ---- print order

export const PrintOrder = (orderId, itemId) => async (dispatch) => {
  try {
    dispatch({ type: PRINT_ORDERS_REQUEST });
    const res = await fetch(
      `${ApiURL}/order/printEticket/${orderId}/${itemId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("instapass_user_token"),
        },
      }
    );
    dispatch({ type: PRINT_ORDERS_FAIL });
    const data = await res.json();
    if (res.status === 400) {
      return toast.error(data.message);
    } else {
      toast.success(data.message);
      dispatch({ type: PRINT_ORDERS_SUCCESS });
      downloadFile(data?.file);
    }
  } catch (error) {
    dispatch({ type: PRINT_ORDERS_ERROR });
  }
};

const downloadFile = (base64String, fileName = "Instapass Ticket.pdf") => {
  try {
    // Decode the Base64 string
    var byteCharacters = atob(base64String);

    // Convert the binary string to an array buffer
    var arrayBuffer = new ArrayBuffer(byteCharacters.length);
    var byteArray = new Uint8Array(arrayBuffer);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteArray[i] = byteCharacters.charCodeAt(i);
    }

    // Create a Blob from the array buffer
    var blob = new Blob([arrayBuffer], { type: "application/pdf" });

    // Create a link element
    var a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName; // Specify the file name

    // Append the anchor element to the document body
    document.body.appendChild(a);

    // Trigger the download
    a.click();

    // Cleanup
    document.body.removeChild(a);
  } catch (error) {
    console.error("Error downloading file:", error);
    // You can handle the error here
  }
};
