import React, { useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import TrendingCard from "../../EventCard/TrendingCard/TrendingCard";
import SwiperCore from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "./styles/styles.css";
import "./styles/trendingEvent.css";
import NoDataMessag01 from "../../NoDataMessage/NoDataMessag01";
SwiperCore.use([Navigation]);

const TrendingEvent = () => {
  const swiperRef = useRef(null);
  const FeaturedEventsdata = useSelector((state) => state.Admin.featuredEvents);

  const filterTrndingEvent = useMemo(() => {
    return FeaturedEventsdata?.filter((item, index) =>
      item.isFeatured === false);
  }, [FeaturedEventsdata])



  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const breakPointsValues = {
    // when window width is >= 658px
    280: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    // when window width is >= 658px
    650: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    // when window width is >= 768px
    900: {
      slidesPerView: 3,
      spaceBetween: 20,
    },

    1024: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    // when window width is >= 1200px
    1280: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
    1550: {
      slidesPerView: 5,
      spaceBetween: 30,
    },
  }

  return (
   <>
    <div className="trendingEvent relative text-color2 bg-color1">
      <h2 className="text-redColor text-center text-[38px] font-[600] mb-[50px] tracking-[15px] t_h">
        Trending live events
      </h2>
      {
        filterTrndingEvent?.length !== 0 ?
          <>
            <div className="w-full flex gap-3 justify-end place-items-end px-2">
              <div
                className="border-[2px] border-redColor w-[35px] cursor-pointer h-[35px] flex justify-center place-items-center rounded-full"
                onClick={goPrev}
              >
                <IoIosArrowBack className="text-[23px] hover:text-redColor" />
              </div>
              <div
                className="border-[2px] border-redColor w-[35px] cursor-pointer h-[35px] flex justify-center place-items-center rounded-full"
                onClick={goNext}
              >
                <IoIosArrowForward className="text-[23px] hover:text-redColor" />
              </div>
            </div>
            <Swiper
              ref={swiperRef}
              slidesPerView={4}
              spaceBetween={0}
              freeMode={true}
              navigation={true}
              modules={[Pagination, Navigation]}
              breakpoints={breakPointsValues}
              className="mySwiper my-2 mx-auto 2xl:gap-[30px] pt-1 pb-3"
            >
              {filterTrndingEvent &&
                filterTrndingEvent.map((item, index) => {
                  return (
                    <SwiperSlide
                      className="shrink-0 !flex !justify-center !place-items-center  !h-[400px]"
                      key={index}
                    >
                      <TrendingCard key={index} item={item} index={index} />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </>
          :
          <NoDataMessag01 value="Trending Events" />
      }
    </div>
   </>
  );
};

export default TrendingEvent;
