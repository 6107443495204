import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { IoHomeOutline } from "react-icons/io5";
import { FiShoppingBag } from "react-icons/fi";
import { MdPassword } from "react-icons/md";
import { RiLogoutCircleLine } from "react-icons/ri";
import { LogoutUserFunc } from "../../../redux/actions/User";
import { RxCross1 } from "react-icons/rx";
import { LuLayoutDashboard } from "react-icons/lu";


const Sidebar = ({ active, setactive, sidebarShow, setShowSidebar }) => {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="bg-color1 text-color2 h-[550px] border-r-[1px] border-r-color4 sticky top-0 user_profile_sidebar">
      {/* --- header  */}
      <div className="flex justify-between shadow-md place-items-start gap-2 py-[5px] px-3">
        <RxCross1
          className="text-[23px] cursor-pointer profileburger"
          onClick={() => setShowSidebar(false)}
        />
      </div>
      {/* ------- menus  */}
      <div className="py-5 px-3 sidebar_profile_box">
        <NavLink to="/" onClick={() => setShowSidebar(false)}>
          <div
            className={`flex profiel_sidebar_menu justify-start place-items-center gap-3 mb-3 py-2 px-2 cursor-pointer  rounded-md `}
          >
            <IoHomeOutline className="text-[25px] text-[#CD292E]" />
            <p className="text-[20px] font-normal text-color2">Home</p>
          </div>
        </NavLink>
        <div
          className={`flex profiel_sidebar_menu justify-start place-items-center gap-3 mb-3 py-2 px-2 cursor-pointer  rounded-md ${active === 2 ? "active_profiel_sidebar !text-color1 " : null
            }`}
          onClick={() => {
            setactive(2);
            setShowSidebar(false);
          }}
        >
          <MdPassword className="text-[25px] text-[#CD292E]" />
          <p className="text-[20px] font-normal ">Account Details</p>
        </div>
        <div
          className={`flex profiel_sidebar_menu justify-start place-items-center gap-3 mb-3 py-2 px-2 cursor-pointer  rounded-md ${active === 1 ? "active_profiel_sidebar !text-color1" : null
            }`}
          onClick={() => {
            setactive(1);
            setShowSidebar(false);
          }}
        >
          <FiShoppingBag className="text-[25px] text-[#CD292E]" />
          <p className="text-[20px] font-normal">My Tickets</p>
        </div>
        {
          user?.role === "admin" &&
          <NavLink to={"/admin/dashboard"}
            className={`flex profiel_sidebar_menu justify-start place-items-center gap-3 mb-3 py-2 px-2 cursor-pointer  rounded-md ${active === 3 ? "active_profiel_sidebar !text-color1" : null
              }`}
          >
            <LuLayoutDashboard className="text-[25px] text-[#CD292E]" />
            <p className="text-[20px] font-normal">Admin Dashboard</p>
          </NavLink>
        }
        <div
          className={`flex profiel_sidebar_menu justify-start place-items-center gap-3 mb-3 py-2 px-2 cursor-pointer  rounded-md `}
          onClick={() => {
            dispatch(LogoutUserFunc(navigate));
            setShowSidebar(false);
          }}
        >
          <RiLogoutCircleLine className="text-[25px] text-[#CD292E]" />
          <p className="text-[20px] font-normal ">Logout</p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
