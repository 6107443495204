import React from 'react';
import "swiper/css";
import "swiper/css/pagination";
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import FeaturedEvent from '../../EventCard/LatestCard/FeaturedEvent';

const EventCarosal = ({swiperRef , events}) => {
  const isloading = useSelector((state) => state.events.isLoading);

  return (
    <Swiper
    ref={swiperRef}
    slidesPerView={4}
    spaceBetween={0}
    freeMode={true}
    navigation={true}
    modules={[Pagination, Navigation]}
    breakpoints={{
      // when window width is >= 658px
      280: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      // when window width is >= 658px
      650: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      // when window width is >= 768px
      900: {
        slidesPerView: 3,
        spaceBetween: 20,
      },

      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      // when window width is >= 1200px
      1280: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      // when window width is >= 1200px
      1760: {
        slidesPerView: 5,
        spaceBetween: 30,
      },
    }}
    className="flex justify-start place-items-center gap-[20px] flex-nowrap  mx-auto 2xl:gap-[30px] my-2 "
  >
    {" "}
    {isloading
      ? // <Loading />
        null
      : events &&
        events?.length > 0 &&
        events?.map((item, index) => {
          // if (Date.now() > new Date(item?.event[0]?.occurs_at)?.getTime()) {
          //   // eslint-disable-next-line
          //   return;
          // }
          return (
            <SwiperSlide className="shrink-0 !flex !justify-center !place-items-center" key={index}>
              <FeaturedEvent
                key={index}
                item={item?.event[0]}
                index={index + 1}
              />
            </SwiperSlide>
          );
        })}
  </Swiper>
  )
}

export default EventCarosal
