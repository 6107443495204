// ----- create
export const COUPON_CODE_CREATE_REQUEST = "COUPON_CODE_CREATE _REQUEST";
export const COUPON_CODE_CREATE_REQUEST_FAIL =
  "COUPON_CODE_CREATE_REQUEST_FAIL";
export const COUPON_CODE_CREATE_SUCCESS = "COUPON_CODE_CREATE _SUCCESS";
export const COUPON_CODE_CREATE_ERROR = "COUPON_CODE_CREAT_ERROR";

// ---- apply coupon code
export const APPLY_COUPON_REQUEST = "APPLY_COUPON _REQUEST";
export const APPLY_COUPON_REQUEST_FAIL = "APPLY_COUPON_REQUEST_FAIL";
export const APPLY_COUPON_SUCCESS = "APPLY_COUPON _SUCCESS";
export const APPLY_COUPON_ERROR = "COUPON_CODE_ERROR";
