import { createReducer } from "@reduxjs/toolkit";
import {
  CREATE_ADMIN_CAROUSAL_FAIL,
  CREATE_ADMIN_CAROUSAL_REQUEST,
  CREATE_ADMIN_CAROUSAL_REQUEST_FAIL,
  CREATE_ADMIN_CAROUSAL_SUCCESS,
  CREATE_EVENTS_ERROR,
  CREATE_EVENTS_REQUEST,
  CREATE_EVENTS_REQUEST_FAIL,
  CREATE_EVENTS_SUCCESS,
  DELETE_CAROUSAL_FAIL,
  DELETE_CAROUSAL_REQUEST,
  DELETE_CAROUSAL_REQUEST_FAIL,
  DELETE_CAROUSAL_SUCCESS,
  DELETE_FEATURED_EVENTS_ERROR,
  DELETE_FEATURED_EVENTS_REQUEST,
  DELETE_FEATURED_EVENTS_REQUEST_FAIL,
  DELETE_FEATURED_EVENTS_SUCCESS,
  GET_CAROUSAL_FAIL,
  GET_CAROUSAL_REQUEST,
  GET_CAROUSAL_REQUEST_FAIL,
  GET_CAROUSAL_SUCCESS,
  GET_FEATURED_EVENTS_ERROR,
  GET_FEATURED_EVENTS_REQUEST,
  GET_FEATURED_EVENTS_REQUEST_FAIL,
  GET_FEATURED_EVENTS_SUCCESS,
  GET_ORDERBYID_ADMIN_ERROR,
  GET_ORDERBYID_ADMIN_REQUEST,
  GET_ORDERBYID_ADMIN_REQUEST_FAIL,
  GET_ORDERBYID_ADMIN_SUCCESS,
  GET_USER_ORDER_BYID_ADMIN_ERROR,
  GET_USER_ORDER_BYID_ADMIN_REQUEST,
  GET_USER_ORDER_BYID_ADMIN_REQUEST_FAIL,
  GET_USER_ORDER_BYID_ADMIN_SUCCESS,
  ORDER_STATUS_ADMIN_ERROR,
  ORDER_STATUS_ADMIN_REQUEST,
  ORDER_STATUS_ADMIN_REQUEST_FAIL,
  ORDER_STATUS_ADMIN_SUCCESS,
} from "../constant/AdminConstant";

const initialsVlaues = {
  adminloading: false,
  carousalData: [],
  featuredEvents: [],
  UserOrderById: [],
  SingleOrderDeatils: {},
};

export const AdminReducer = createReducer(initialsVlaues, (builder) => {
  builder
    .addCase(CREATE_ADMIN_CAROUSAL_REQUEST, (state) => {
      state.adminloading = true;
    })
    .addCase(CREATE_ADMIN_CAROUSAL_REQUEST_FAIL, (state) => {
      state.adminloading = false;
    })
    .addCase(CREATE_ADMIN_CAROUSAL_SUCCESS, (state, action) => {
      state.adminloading = false;
    })
    .addCase(CREATE_ADMIN_CAROUSAL_FAIL, (state) => {
      state.adminloading = false;
    })
    // --------- get carousal
    .addCase(GET_CAROUSAL_REQUEST, (state) => {
      state.adminloading = true;
    })
    .addCase(GET_CAROUSAL_REQUEST_FAIL, (state) => {
      state.adminloading = false;
    })
    .addCase(GET_CAROUSAL_SUCCESS, (state, action) => {
      state.adminloading = false;
      state.carousalData = action.payload;
    })
    .addCase(GET_CAROUSAL_FAIL, (state) => {
      state.adminloading = false;
    })
    // ----delete
    .addCase(DELETE_CAROUSAL_REQUEST, (state) => {
      state.adminloading = true;
    })
    .addCase(DELETE_CAROUSAL_REQUEST_FAIL, (state) => {
      state.adminloading = false;
    })
    .addCase(DELETE_CAROUSAL_SUCCESS, (state, action) => {
      state.adminloading = false;
    })
    .addCase(DELETE_CAROUSAL_FAIL, (state) => {
      state.adminloading = false;
    })
    // ----------------------- create event
    .addCase(CREATE_EVENTS_REQUEST, (state) => {
      state.adminloading = true;
    })
    .addCase(CREATE_EVENTS_REQUEST_FAIL, (state) => {
      state.adminloading = false;
    })
    .addCase(CREATE_EVENTS_SUCCESS, (state, action) => {
      state.adminloading = false;
      state.featuredEvents = action.payload;
    })
    .addCase(CREATE_EVENTS_ERROR, (state) => {
      state.adminloading = false;
    })
    // ------- get all featured event
    .addCase(GET_FEATURED_EVENTS_REQUEST, (state) => {
      state.adminloading = true;
    })
    .addCase(GET_FEATURED_EVENTS_REQUEST_FAIL, (state) => {
      state.adminloading = false;
    })
    .addCase(GET_FEATURED_EVENTS_SUCCESS, (state, action) => {
      state.adminloading = false;
      state.featuredEvents = action.payload;
    })
    .addCase(GET_FEATURED_EVENTS_ERROR, (state) => {
      state.adminloading = false;
    })
    // ----- delete featured event
    .addCase(DELETE_FEATURED_EVENTS_REQUEST, (state) => {
      state.adminloading = true;
    })
    .addCase(DELETE_FEATURED_EVENTS_REQUEST_FAIL, (state) => {
      state.adminloading = false;
    })
    .addCase(DELETE_FEATURED_EVENTS_SUCCESS, (state, action) => {
      state.adminloading = false;
      state.featuredEvents = action.payload;
    })
    .addCase(DELETE_FEATURED_EVENTS_ERROR, (state) => {
      state.adminloading = false;
    })

    // ------ get user orders by id
    .addCase(GET_USER_ORDER_BYID_ADMIN_REQUEST, (state) => {
      state.adminloading = true;
    })
    .addCase(GET_USER_ORDER_BYID_ADMIN_REQUEST_FAIL, (state) => {
      state.adminloading = false;
    })
    .addCase(GET_USER_ORDER_BYID_ADMIN_SUCCESS, (state, action) => {
      state.adminloading = false;
      state.UserOrderById = action.payload;
    })
    .addCase(GET_USER_ORDER_BYID_ADMIN_ERROR, (state) => {
      state.adminloading = false;
    })
    // ---- order by id
    .addCase(GET_ORDERBYID_ADMIN_REQUEST, (state) => {
      state.adminloading = true;
    })
    .addCase(GET_ORDERBYID_ADMIN_REQUEST_FAIL, (state) => {
      state.adminloading = false;
    })
    .addCase(GET_ORDERBYID_ADMIN_SUCCESS, (state, action) => {
      state.adminloading = false;
      state.SingleOrderDeatils = action.payload;
    })
    .addCase(GET_ORDERBYID_ADMIN_ERROR, (state) => {
      state.adminloading = false;
    })

    // === update status
    .addCase(ORDER_STATUS_ADMIN_REQUEST, (state) => {
      state.adminloading = true;
    })
    .addCase(ORDER_STATUS_ADMIN_REQUEST_FAIL, (state) => {
      state.adminloading = false;
    })
    .addCase(ORDER_STATUS_ADMIN_SUCCESS, (state, action) => {
      state.adminloading = false;
    })
    .addCase(ORDER_STATUS_ADMIN_ERROR, (state) => {
      state.adminloading = false;
    });
});
