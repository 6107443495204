import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  FilterEvent,
  GetAllEvents,
  SearchEvents,
  filterEventArrya,
} from "../../../redux/actions/Events";
import { updateFiltrationView } from "../../../redux/actions/State";
import { RxCross1 } from "react-icons/rx";
import { ReactSVG } from "react-svg";
import { GrLocation } from "react-icons/gr";
import CfSVG from "../../../assets/svg/calendarFilter.svg";
import filterSVG from "../../../assets/svg/filter.svg";
import axios from "axios";
import { IoLocation } from "react-icons/io5";


const SidebarCategory = ({
  IsSearch = false,
  tickets = false,
  setIsFilter,
  startdate,
  setstartdate,
  enddate,
  setenddate,
  setpage,
  name = ""
}) => {
  const StartdateInputRef = useRef(null);
  const EnddateInputRef = useRef(null);

  const [location, setlocation] = useState("");
  const [locationObj, setlocationObj] = useState({});
  const [results, setResults] = useState([]);
  const [showLocationDiv, setShowLocationDiv] = useState(false);

  const moment = require("moment");
  const dispatch = useDispatch();


  const searchFunc = () => {
    if (!enddate && !startdate && !location) {
      return;
    } else {
      if (location) {
        // setlocationObj({});
      }
      setIsFilter(true);
      setpage(1);
      const startDateFormatted = startdate
        ? moment(startdate).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
      const endDateFormatted = enddate
        ? moment(enddate).format("YYYY-MM-DD")
        : moment().add(60, "days").format("YYYY-MM-DD");
      dispatch(filterEventArrya());
      dispatch(
        FilterEvent(startDateFormatted, endDateFormatted, 1, results[0], IsSearch)
      );
    }
  };


  // ---- search location
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/search?format=json&q=${location}`
        );
        setResults(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (location.trim() !== "") {
      fetchLocations();
    }
  }, [location]);

  const handleSelect = (selectedLocation) => {
    setlocation(selectedLocation.display_name);
    setlocationObj(selectedLocation);
    setResults([]);
  };


  const handDateleLabelClick = (value) => {
    if (value === "start") {
      if (StartdateInputRef.current) {
        StartdateInputRef.current.showPicker();
      }
    }else{
      if (EnddateInputRef.current) {
        EnddateInputRef.current.showPicker();
      }
    }
  };






  return (
    <>
      <div className="filtersidebar py-[30px] !bg-color1 !z-[999999999]">
        <div className="">
          <div className="my-5 flec flex-col gap-2 w-full">
            <div className="flex justify-between place-items-center ">
              <RxCross1
                className="text-[23px] cursor-pointer crosssidebar"
                onClick={() => dispatch(updateFiltrationView(false))}
              />
            </div>
            <div className="flex justify-start place-items-center gap-[6px]">
              <ReactSVG src={filterSVG} />
              <p className="filter_text">Filter</p>
            </div>
            <div className="my-2 flex justify-between place-items-end gap-[5px]">
              <div className="flex justify-center place-items-center">
                <ReactSVG src={CfSVG} />
              </div>
              <div className="">
                <p className="text-[14px] mb-1 text-color5 date_text">
                  Start Date
                </p>
                <input
                  ref={StartdateInputRef}
                  value={startdate}
                  onChange={(e) => setstartdate(e.target.value)}
                  type="date"
                  className="text-color2 flex-shrink-0 dateinput_category px-2 rounded-md w-full bg-transparent py-2 outline-none"
                  style={{ color: "white" }}
                  id="start"
                  onClick={handDateleLabelClick}
                />

              </div>
              <div className="">
                <p className="text-[14px] mb-1 text-color5 date_text">End Date</p>
                <input
                  ref={EnddateInputRef}
                  value={enddate}
                  onChange={(e) => setenddate(e.target.value)}
                  type="date"
                  placeholder="Start date"
                  className="text-color2 flex-shrink-0  dateinput_category px-2 rounded-md w-full bg-transparent  py-2  outline-none"
                  style={{ color: "white" }}
                  id="start"
                  onClick={handDateleLabelClick}
                />
              </div>
            </div>
          </div>
          <div className="my-2 flex justify-start place-items-end gap-[10px]">
            <div className="flex justify-center place-items-center">
              <GrLocation className="text-[40px]" />
            </div>
            <div className="w-full">
              <p className="text-[14px] mb-1 text-color5 date_text">Location</p>
              <input
                value={location}
                id="location-input"
                onChange={(e) => {
                  setlocation(e.target.value);
                  setShowLocationDiv(false);
                }}
                type="text"
                placeholder="Search by location"
                className="text-color2 focus:bg-transparent  px-2 rounded-md !text-left !max-w-[220px] bg-transparent  py-2  outline-none"
                style={{ color: "white" }}
              />
            </div>
          </div>
          {!showLocationDiv && location && (
            <div
              className="bg-black mt-5 px-2 py-1 rounded-md min-h-auto max-h-[250px] overflow-hidden overflow-y-auto"
              style={{
                boxShadow: "0px 0px 5px 1px rgba(205, 41, 46, 0.45)",
              }}
            >
              <ul className="">
                {results.map((result) => (
                  <li
                    key={result.place_id}
                    onClick={() => {
                      handleSelect(result);
                      setShowLocationDiv(true);
                    }}
                    className="cursor-pointer my-5 flex justify-start place-items-start gap-2 text-[15px] border-b-[1px] border-b-[#444444] pb-1"
                  >
                    <IoLocation className="text-redColor !text-[20px] flex-shrink-0" /> {result.display_name}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="my-5">
          <div className="flex flex-col gap-2">
            <div className=" my-3 flex justify-center place-items-center gap-3">
              <button
                onClick={searchFunc}
                className="filter-f-btn w-full px-[16px] py-2 bg-transparent  !bg-color1 mt-3 rounded-md cursor-pointer"
              >
                Filter
              </button>
              <button
                onClick={() => {
                  if (!startdate && !enddate && !location) {
                    return;
                  }
                  if (IsSearch) {
                    dispatch(SearchEvents(name, location));
                  } else {
                    setIsFilter(false);
                    setstartdate("");
                    setenddate("");
                    setlocation("");
                    dispatch(GetAllEvents(1, location));
                  }
                }}
                className="filter-f-btn w-full px-[16px] py-2 bg-transparent  !bg-color1 mt-3 rounded-md cursor-pointer"
              >
                Clear Filter
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarCategory;

