import { FaCalendar } from "react-icons/fa";
import { GiTheater } from "react-icons/gi";
import { GiSoccerBall } from "react-icons/gi";
import { PiParkDuotone } from "react-icons/pi";
import { BiCameraMovie } from "react-icons/bi";
import { MdOutlineFestival } from "react-icons/md";
import { MdOutlineTheaterComedy } from "react-icons/md";
import C5Image from "../../assets/Categories/SportComedy.png";
import C1Image from "../../assets/Categories/CategorySport.png";
import C6Image from "../../assets/Categories/categoryMovies.png";
import C2Image from "../../assets/Categories/Categoryconcert.png";
import C7Image from "../../assets/Categories/CategoryTheaters.png";
import C3Image from "../../assets/Categories/CategoryFestivals.png";
import C4Image from "../../assets/Categories/CategoryThemePark.png";


export const data = [
    {
      icon: <GiSoccerBall />,
      name: "Sports",
      id: 1,
      img: C1Image,
      subCategory :[
        {
            name : "Fighting",
            id : 44
        },
        {
            name : "Horse Racing",
            id : 33
        },
        {
            name : "Football",
            id : 19
        },
        {
            name : "Baseball",
            id : 2
        },
        {
            name : "Auto Racing",
            id : 27
        },
        {
            name : "Hockey",
            id : 15
        },
        {
            name : "Basketball",
            id : 7
        },
        {
            name : "Golf",
            id : 24
        },
        {
            name : "Wrestling",
            id : 45
        },
        {
            name : "Cycling",
            id : 125
        },
        {
            name : "Volleyball",
            id : 128
        },
        {
            name : "Cricket",
            id : 210
        },
        {
            name : "Ice Skating",
            id : 993
        },
        {
            name : "Swimming",
            id : 1104
        },
        {
            name : "Gymnastics",
            id : 1277
        },
      ]
    },
    {
      icon: <FaCalendar />,
      name: "Concert",
      id: 54,
      img: C2Image,
      subCategory :[
        {
            name : "World-music",
            id : 65
        },
        {
            name : "Classical",
            id : 67
        },
        {
            name : "Comedy",
            id : 55
        },
        {
            name : "Family",
            id : 56
        },
        {
            name : "Alternative Rock",
            id :  82
        },
        {
            name : "Cabaret",
            id :  83
        },
        {
            name : "Dance/Electronic",
            id :  84
        },
        {
            name : "Latin",
            id :  85
        },
        {
            name : "New Age & Spiritual",
            id :  86
        },
        {
            name : "Rap & Hip-Hop",
            id :  59
        },
        {
            name : "Rock & Pop",
            id :  57
        },
        {
            name : "Indie Music",
            id :  58
        },
        {
            name : "Reggae",
            id :  1111
        },
      ]
    },
    {
      icon: <MdOutlineFestival />,
      name: "Festivals",
      id: 73,
      img: C3Image,
      subCategory : []
    },
    {
      icon: <PiParkDuotone />,
      name: "Theme Park",
      img: C4Image,
      id: 2,
      subCategory : []

    },
    {
      icon: <MdOutlineTheaterComedy />,
      name: "Comedy",
      id: 55,
      img: C5Image,
      subCategory : []

    },
    {
      icon: <BiCameraMovie />,
      name: "Movies",
      id: 138,
      img: C6Image,
      subCategory : []

    },
    {
      icon: <GiTheater />,
      name: "Theater",
      id: 68,
      img: C7Image,
      subCategory :[
        {
            name : "Musicals",
            id : 69
        },
        {
            name : "Entertainment Shows",
            id : 212
        },
        {
            name : "Las Vegas Shows",
            id : 996
        },
        {
            name : "Off-Broadway",
            id : 998
        },
        {
            name : "Magic",
            id : 1001
        },
        {
            name : "Broadway",
            id : 1067
        },
        {
            name : "Actress",
            id : 1109
        },
      ]
    },
  ];