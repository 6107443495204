import React, { useMemo } from "react";
import Lottie from "lottie-react";
import SVGGET from "./Animation - 1706103131287 (2).json";

const Loading = () => {
  const Memozation = useMemo(() => {
    return (
      <div>
        <Lottie
          animationData={SVGGET}
          loop={true}
          style={{ width: "300px", height: "auto" }}
          className="svges"
        />
      </div>
    );
  }, [SVGGET]);

  return Memozation;
};

export default Loading;
