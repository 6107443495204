import React, { useEffect, useRef } from 'react'

const HorizontalExpediaBanner = () => {
      // xpedia
  const affiliateBanner03Ref = useRef(null)
  const depen03 = document?.getElementById(window.innerWidth > 678 ? "affiliateBanner03" : "affiliateBanner04")?.innerHTML;
  let affiliateBannerHtml03 = document?.getElementById(window.innerWidth > 678 ? "affiliateBanner03" : "affiliateBanner04")?.innerHTML;

  useEffect(() => {
    affiliateBanner03Ref.current.innerHTML = affiliateBannerHtml03;
    affiliateBanner03Ref.current.style.display = "block";

  }, [affiliateBanner03Ref, depen03])
  return (
    <div ref={affiliateBanner03Ref} className="my-[20px] w-[100%] overflow-x-auto horizontal"></div>

  )
}

export default HorizontalExpediaBanner
