import fb from "../../assets/Facebook.svg";
import tweet from "../../assets/Twitter.svg";
import insta from "../../assets/Instagram.svg";
import linkedin from "../../assets/LinkedIn.svg";
import Logo from "../Logo/Logo";
import { MdMarkEmailRead, MdPhone } from "react-icons/md";
import { NavLink } from "react-router-dom";
import "./styles/Footer.css"

const Footer2 = () => {
  return (
    <div className="w-full bg-[white] text-black flex flex-col border-t-[1px] border-t-[#cd292e42]">
      <div className="flex justify-between footer2 px-[40px] py-[60px]">
        <div className="w-[270px] h-[78px] flex flex-col gap-[24px] footer_logodiv">
          <div className="flex justify-start">
            <Logo isFooter={true} />
          </div>
          <div className="w-[177px] h-[19px] gap-[22px] flex items-center">
            <a
              href="https://www.facebook.com/Instapasstickets?mibextid=LQQJ4d"
              target="_blank"
              rel="noreferrer"
              className="text-gray-500 transition-colors duration-300 hover:text-teal-accent-400"
            >
              <img className="w-[11px] h-[19px]" src={fb} alt="fb-img" />
            </a>
            <a
              href="https://x.com/GetInstapass"
              target="_blank"
              rel="noreferrer"
              className="text-gray-500 transition-colors duration-300 hover:text-teal-accent-400"
            >
              <img
                className="w-[19px] h-[15px]"
                src={tweet}
                alt="twitter-img"
              />
            </a>
            <a
              href="https://www.instagram.com/instapass.shop?igsh=MzRlODBiNWFlZA=="
              target="_blank"
              rel="noreferrer"
              className="text-gray-500 transition-colors duration-300 hover:text-teal-accent-400"
            >
              <img className="w-[19px] h-[19px]" src={insta} alt="insta-img" />
            </a>
            <a
              href="https://www.linkedin.com/company/instapass-app/"
              target="_blank"
              rel="noreferrer"
              className="text-gray-500 transition-colors duration-300 hover:text-teal-accent-400"
            >
              <img
                className="w-[19px] h-[18px]"
                src={linkedin}
                alt="linked-img"
              />
            </a>
          </div>
        </div>
        
        <div className="flex flex-col gap-[20px] footer_categories">
          <h6 className="text-[20px] leading-[22px] font-bold">Category</h6>
          <div className="flex flex-col gap-[18px]">
            <p className="font-[400] text-[18px] leading-[20px] items-center text-[#6F6C90]">
              <NavLink to={`/tickets/category=1`}>Sports</NavLink>
            </p>
            <p className="font-[400] text-[18px] leading-[20px] items-center text-[#6F6C90]">
              <NavLink to={`/tickets/category=54`}>Concert</NavLink>
            </p>
            <p className="font-[400] text-[18px] leading-[20px] items-center text-[#6F6C90]">
              <NavLink to={`/tickets/category=68`}>Theater</NavLink>
            </p>
            <p className="font-[400] text-[18px] leading-[20px] items-center text-[#6F6C90]">
              <NavLink to={`/tickets/category=138`}>Movies</NavLink>
            </p> 
            <p className="font-[400] text-[18px] leading-[20px] items-center text-[#6F6C90]">
              <NavLink to={`/tickets/category=73`}>Festivals</NavLink>
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-[20px] footer_pages">
          <h6 className="text-[20px] leading-[22px] font-[700]">Pages</h6>
          <div className="flex flex-col gap-[18px]">
            <p className="font-[400] text-[18px] leading-[20px] items-center text-[#6F6C90]">
              <NavLink to="/">Home</NavLink>
            </p>
            <p className="font-[400] text-[18px] leading-[20px] items-center text-[#6F6C90]">
              <NavLink to="/tickets">All Events</NavLink>
            </p>
            <p className="font-[400] text-[18px] leading-[20px] items-center text-[#6F6C90]">
              <NavLink to="/newsfeed/1">News Feed</NavLink>
            </p>
            <p className="font-[400] text-[18px] leading-[20px] items-center text-[#6F6C90]">
              <NavLink to="/aboutUs">About Us</NavLink>
            </p>
            {/* <p className="font-[400] text-[18px] leading-[20px] items-center text-[#6F6C90]">
              <a href="/updates">Updates</a>
            </p> */}
          </div>
        </div>

        <div className="flex flex-col gap-[20px] footer_Support">
          <h6 className="text-[20px] leading-[22px] font-bold">Support</h6>
          <div className="flex flex-col gap-[18px]">
            <p className="font-[400] text-[18px] leading-[20px] items-center text-[#6F6C90]">
              <NavLink to="/privacyPolicy">Privacy Policy</NavLink>
            </p>
            <p className="font-[400] text-[18px] leading-[20px] items-center text-[#6F6C90]">
            <NavLink to="/terms&conditions">Terms & Conditions</NavLink>
            </p>
            <p className="font-[400] text-[18px] leading-[20px] items-center text-[#6F6C90]">
            <NavLink to="/">Contact Us</NavLink>
            </p>
            <p className="font-[400] text-[18px] leading-[20px] items-center text-[#6F6C90]">
            <NavLink to="/aboutUs">About Us</NavLink>
            </p>
            
          </div>
        </div>
        

        <div className="w-[250px] footer_contact flex flex-col gap-[20px]">
          <h6 className="text-[20px] leading-[22px] font-bold">Contact us</h6>
          <div className="flex flex-col gap-[18px]">
            <p className="flex  gap-2 font-[400] text-[18px] leading-[20px] items-center text-[#6F6C90]">
              <MdMarkEmailRead className="text-[22px] flex-shrink-0 text-redColor" />
              <a href="mailto:Admin@instapass.shop">Admin@instapass.shop</a>
            </p>
            <p className="flex gap-2 font-[400] text-[18px] leading-[20px] items-center text-[#6F6C90]">
              <MdPhone className="text-[22px] text-redColor" />
              1(844) INSTA51
            </p>
            {/* <p className='flex font-[400] text-[18px] leading-[20px] text-[#6F6C90]'>
                            <img className='w-[20px] h-[20px] mr-[8px]' src={location} alt="location-icon" />
                            794 Mcallister St, San Francisco, 94102
                        </p> */}
            <div className="md:max-w-md lg:col-span-2 w-[100%]">
              <div id="mc_embed_shell">
                <link
                  href="//cdn-images.mailchimp.com/embedcode/classic-061523.css"
                  rel="stylesheet"
                  type="text/css"
                />

                <div id="mc_embed_signup">
                  <form
                    action="https://Getinstapass.us20.list-manage.com/subscribe/post?u=6c824f174d2c0d626598ffd2e&amp;id=0298d08a9c&amp;f_id=002522eaf0"
                    method="post"
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    className="validate !m-0"
                    target="_blank"
                  >
                    <div id="mc_embed_signup_scroll">
                      <div className="flex flex-col  md:flex-row mc-field-group gap-2 !w-[100%]">
                        <input
                          type="email"
                          name="EMAIL"
                          placeholder="EMAIL"
                          className="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                          required=""
                        />
                        <input
                          type="submit"
                          name="subscribe"
                          className="inline-flex cursor-pointer bg-black items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                          value="Subscribe"
                        />
                      </div>
                      <div hidden="">
                        <input type="hidden" name="tags" value="408498" />
                      </div>
                      <div id="mce-responses" className="clear foot">
                        <div
                          className="response"
                          id="mce-error-response"
                          style={{ display: "none" }}
                        ></div>
                        <div
                          className="response"
                          id="mce-success-response"
                          style={{ display: "none" }}
                        ></div>
                      </div>
                      <div
                        aria-hidden="true"
                        style={{ position: "absolute", left: "-5000px" }}
                      >
                        <input
                          type="text"
                          name="b_6c824f174d2c0d626598ffd2e_0298d08a9c"
                          tabIndex="-1"
                          value=""
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <script
                  type="text/javascript"
                  src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="border border-[#D9DBE9]"></div>

      <div className="flex justify-between items-center px-[40px] py-[20px] bottom_footer">
        <div className="flex items-center">
          <p className="font-[400] text-[18px] leading-[30px] text-[#6F6C90]">
            Copyright © 2024 Instapass
          </p>
        </div>

        <div className="flex items-center gap-[10px] bottom_footer_bottom">
          <p className="font-[400] text-[18px] leading-[30px] text-[#6F6C90]">
            All Rights Reserved |
          </p>
          <NavLink
            to="/terms&conditions"
            className="underline cursor-pointer text-[18px] font-[400] leading-[30px]"
          >
            Terms and Conditions
          </NavLink>
          <NavLink
            to="/privacyPolicy"
            className="underline cursor-pointer text-[18px] font-[400] leading-[30px]"
          >
            Privacy Policy
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Footer2;
