import React from 'react';
import "../styles/navebar.css"
import Logo from '../../Logo/Logo';
import { CiSearch } from 'react-icons/ci';
import { RxHamburgerMenu } from 'react-icons/rx';
import { NavLink, useNavigate } from 'react-router-dom';

const DeskTopNavbar02 = ({searchFunction , user , data ,search ,setSearch , setshowmenu , subId ,setShowSubId ,matchedItem ,UserAvatar }) => {
  const navigate = useNavigate();

    return (
        <div className="z-[100] new_header  bg-white text-black flex w-full gap-5  justify-between place-items-center py-[25px] px-[10px] 2xl:px-[100px] max-w-[2000px] mx-auto">

            {/* ------- left header  */}
            <div className="mob_header w-full ">
                <div className="w-full flex justify-between place-items-center gap-3 ">
                    <Logo />

                    <div className="searchbarbox tab_searchbarbox flex w-full justify-start place-items-center rounded-[10px] gap-2 py-[6px] px-3">
                        <CiSearch
                            className="text-[18px] cursor-pointer"
                            onClick={() => searchFunction()}
                        />
                        <input
                            className="bg-transparent outline-none text-color5 w-full"
                            type="text"
                            placeholder="Search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    navigate(`tickets/${search}`);
                                }
                            }}
                        />
                    </div>
                    <div>
                        <div className="w-full">
                            <RxHamburgerMenu
                                className="burger text-[28px] mt-[-1px] cursor-pointer"
                                onClick={() => setshowmenu(true)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="right_header  flex justify-start place-items-center">
                <Logo />
            </div>
            {/* ------ right header  */}
            <div className="right_header flex  justify-end place-items-end gap-[30px] 2xl:gap-[80px] w-full pr-[40px]">
                {/* ---- menus  */}
                <div className="new_menus flex w-full justify-end place-items-center">
                    <NavLink to="/">
                        <li className='!text-[18px] !font-[400] '>Home</li>
                    </NavLink>
                    <NavLink to="/tickets">
                        <li className=' !text-[18px] !font-[400]'>All Events</li>
                    </NavLink>
                    <NavLink to="/newsfeed/1">
                        <li className='!text-[18px] !font-[400]'>News Feed</li>
                    </NavLink>
                    <NavLink to="/aboutUs">
                        <li className='!text-[18px] !font-[400]'>About Us</li>
                    </NavLink>

                    <div className="dropdown dropdown-hover !z-[100]">

                        <p tabIndex={0}>
                            <li className='!text-[18px] !font-[400] text-[#7B7583] dropdown_li'>Categories</li>
                        </p>
                        <ul tabIndex={0} className="dropdown-content rounded-lg menu bg-[black] text-white z-[99999999999] w-[400px] p-2 shadow"
                            style={{
                                boxShadow: `0px 0px 15px 1px rgba(205, 41, 46, 0.45)`
                            }}
                        >
                            <div className="max-h-[450px] overflow-y-hidden category_parent z-[99999999999] "
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: ".7fr 1fr"
                                }}
                            >
                                <div className="border-r-[1px] border-r-[#1f1e1e] h-[100%] overflow-y-auto ">
                                    {
                                        data?.map((item, index) => {
                                            return (
                                                <li key={index} className={`${subId === item.id && "bg-[#1a1818]"} hover:bg-[#131212] border-b-[1px] border-b-[#1f1e1e] rounded-none`}
                                                    onMouseEnter={() => {
                                                        setShowSubId(item.id);
                                                    }}
                                                ><p>{item.name}</p></li>
                                            )
                                        })
                                    }
                                </div>
                                <div className="h-[100%] overflow-y-auto category_parent">
                                    {matchedItem && matchedItem.subCategory && matchedItem.subCategory.length > 0 ? (
                                        matchedItem.subCategory.map((item, index) => (
                                            <li key={index} className="hover:bg-[#131212] border-b-[1px] border-b-[#131212]">
                                                <NavLink to={`/tickets/category=${item?.id}`}>{item.name}</NavLink>
                                            </li>
                                        ))
                                    ) : (
                                        <div className="new_category_box shrink-0 w-[100%] hover:border-b-2 hover:border-b-redColor cursor-pointer">
                                            <p className="w-[100%] text-center">No more category found</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ul>
                    </div>
                    {user && user?.email && (
                        <div
                            onClick={() => {
                                navigate(`/user/profile`, {
                                    state: { data: 1 },
                                });
                            }}
                        >
                            <li className='!text-[18px] text-[#7B7583] !font-[400]'>My tickets</li>
                        </div>
                    )}
                </div>
                {/* ---- search bar and btn  */}
                <div className="search_area_btn flex  justify-center place-items-center gap-[10px]">
                    {/* <div className="searchbarbox flex w-full justify-center place-items-center rounded-[10px] gap-2 py-[6px] px-3">
                        <CiSearch
                            className="text-[18px] cursor-pointer"
                            onClick={() => searchFunction()}
                        />
                        <input
                            className="bg-transparent outline-none text-color5"
                            type="text"
                            placeholder="Search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    navigate(`tickets/${search}`);
                                }
                            }}
                        />

                    </div> */}
                    <NavLink to="/">
                                <button className="bg-[white] text-[black] px-[10px] py-[8px] rounded-[4px] w-[150px] border-[1px] border-black">Contact Us</button>
                            </NavLink>
                    {user && user?.firstName ? (
                        user && user?.Avatar ? (
                            <NavLink to={"/user/profile"} className="w-[45px] !h-[45px]">
                                <img
                                    className="w-[45px] !h-[45px] object-cover uppercase font-bold rounded-full border-2 border-white flex justify-center items-center ml-[10px]"
                                    src={`${UserAvatar}`}
                                    alt={`Avatar`}
                                />
                            </NavLink>
                        ) : (
                            <NavLink to="/user/profile" className="w-[45px] h-[45px]">
                                <p className="w-[45px] h-[45px] uppercase font-bold rounded-full border-2 border-white flex justify-center items-center ml-[10px]">
                                    {user?.firstName.slice(0, 1)}
                                </p>
                            </NavLink>
                        )
                    ) : (
                        <>
                            {/* <NavLink to="/registration">
                                <button className="account_btn1">SignUp</button>
                            </NavLink>
                            <NavLink to="/login">
                                <button className="account_btn2">SignIn</button>
                            </NavLink> */}
                            <NavLink to="/login">
                                <button className="bg-[black] text-[white] px-[10px] py-[8px] rounded-[4px] w-[100px] border-[1px] border-black">SignIn</button>
                            </NavLink>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}


export default DeskTopNavbar02
