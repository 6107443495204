export const CREATE_ADMIN_CAROUSAL_REQUEST = "CREATE_ADMIN_CAROUSAL_REQUEST";
export const CREATE_ADMIN_CAROUSAL_REQUEST_FAIL =
  "CREATE_ADMIN_CAROUSAL_REQUEST_FAIL";
export const CREATE_ADMIN_CAROUSAL_SUCCESS = "CREATE_ADMIN_CAROUSAL_SUCCESS";
export const CREATE_ADMIN_CAROUSAL_FAIL = "CREATE_ADMIN_CAROUSAL_FAIL";

// ============  get all carousal
export const GET_CAROUSAL_REQUEST = "GET_CAROUSAL_REQUEST";
export const GET_CAROUSAL_REQUEST_FAIL = "GET_CAROUSAL_REQUEST_FAIL";
export const GET_CAROUSAL_SUCCESS = "GET_CAROUSAL_SUCCESS";
export const GET_CAROUSAL_FAIL = "GET_CAROUSAL_FAIL";

// ==== delete
export const DELETE_CAROUSAL_REQUEST = "DELETE_CAROUSAL_REQUEST";
export const DELETE_CAROUSAL_REQUEST_FAIL = "DELETE_CAROUSAL_REQUEST_FAIL";
export const DELETE_CAROUSAL_SUCCESS = "DELETE_CAROUSAL_SUCCESS";
export const DELETE_CAROUSAL_FAIL = "DELETE_CAROUSAL_FAIL";

// --- create event
export const CREATE_EVENTS_REQUEST = "CREATE_EVENTS_REQUEST";
export const CREATE_EVENTS_REQUEST_FAIL = "CREATE_EVENTS_REQUEST_FAIL";
export const CREATE_EVENTS_SUCCESS = "CREATE_EVENTS_SUCCESS";
export const CREATE_EVENTS_ERROR = "CREATE_EVENTS_ERROR";

// --- get event
export const GET_FEATURED_EVENTS_REQUEST = "GET_FEATURED_EVENTS_REQUEST";
export const GET_FEATURED_EVENTS_REQUEST_FAIL =
  "GET_FEATURED_EVENTS_REQUEST_FAIL";
export const GET_FEATURED_EVENTS_SUCCESS = "GET_FEATURED_EVENTS_SUCCESS";
export const GET_FEATURED_EVENTS_ERROR = "GET_FEATURED_EVENTS_ERROR";

// --- delete featured events
export const DELETE_FEATURED_EVENTS_REQUEST = "DELETE_FEATURED_EVENTS_REQUEST";
export const DELETE_FEATURED_EVENTS_REQUEST_FAIL =
  "DELETE_FEATURED_EVENTS_REQUEST_FAIL";
export const DELETE_FEATURED_EVENTS_SUCCESS = "DELETE_FEATURED_EVENTS_SUCCESS";
export const DELETE_FEATURED_EVENTS_ERROR = "DELETE_FEATURED_EVENTS_ERROR";

// ---- find orders by user id

export const GET_USER_ORDER_BYID_ADMIN_REQUEST =
  "GET_USER_ORDER_BYID_ADMIN_REQUEST";
export const GET_USER_ORDER_BYID_ADMIN_REQUEST_FAIL =
  "GET_USER_ORDER_BYID_ADMIN_REQUEST_FAIL";
export const GET_USER_ORDER_BYID_ADMIN_SUCCESS =
  "GET_USER_ORDER_BYID_ADMIN_SUCCESS";
export const GET_USER_ORDER_BYID_ADMIN_ERROR =
  "GET_USER_ORDER_BYID_ADMIN_ERROR";

// === find order by id
export const GET_ORDERBYID_ADMIN_REQUEST = "GET_ORDERBYID_ADMIN_REQUEST";
export const GET_ORDERBYID_ADMIN_REQUEST_FAIL =
  "GET_ORDERBYID_ADMIN_REQUEST_FAIL";
export const GET_ORDERBYID_ADMIN_SUCCESS = "GET_ORDERBYID_ADMIN_SUCCESS";
export const GET_ORDERBYID_ADMIN_ERROR = "GET_ORDERBYID_ADMIN_ERROR";

// === update order status
export const ORDER_STATUS_ADMIN_REQUEST = "ORDER_STATUS_ADMIN_REQUEST";
export const ORDER_STATUS_ADMIN_REQUEST_FAIL =
  "ORDER_STATUS_ADMIN_REQUEST_FAIL";
export const ORDER_STATUS_ADMIN_SUCCESS = "ORDER_STATUS_ADMIN_SUCCESS";
export const ORDER_STATUS_ADMIN_ERROR = "ORDER_STATUS_ADMIN_ERROR";
