import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Loading from "../Loading/Loading";

const UserAuth = ({ children }) => {
  const user = useSelector((state) => state.user.user);
  const isAuthanticated = useSelector((state) => state.user.isAuthantication);
  const token = localStorage.getItem("instapass_user_token");
  if (token) {
    if (Object.keys(user).length > 0) {
      if (user?.firstName && isAuthanticated === true) {
        return <>{children}</>;
      }
    } else {
      return (
        <div className="w-full flex justify-center place-items-center h-[40vh]">
          <Loading />
        </div>
      );
    }
  } else {
    return <Navigate replace to="/login" />;
  }
};

export default UserAuth;
