import React from "react";
import ForgotPassCompo from "../Components/accounts/ForgotPassword/ForgotPassCompo";

const ForgotPassword = () => {
  return (
    <>
      <ForgotPassCompo />
    </>
  );
};

export default ForgotPassword;
