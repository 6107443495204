import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiMediaURL } from "../../../../setting/GlobleVariable";
import {
  DeleteUserProfiel,
  UpdateUserRole,
} from "../../../../redux/actions/Admin/AdminUser";
import { MdDeleteOutline } from "react-icons/md";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const ClientTable = () => {
  const AdminUsers = useSelector((state) => state.user.AdminAllUser)
    ?.slice(-5)
    ?.reverse();
  const dispacth = useDispatch();

  const handleDeleteUser = (id) => {
    const MySwal = withReactContent(Swal)

    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',

    }).then((result) => {
      if (result.isConfirmed) {
        dispacth(DeleteUserProfiel(id))
      }
    })
  }

  return (
    <>
      <h2 className="text-[30px] ">New User</h2>
      <div class="mt-4  bg-col">
        <div class="w-full overflow-hidden rounded-lg shadow-xs">
          <div class="w-full overflow-x-auto">
            <table class="w-full ">
              <thead>
                <tr class="text-xs font-semibold rounded-t-md tracking-wide text-left text-color2 uppercase border-b border-b-color1 bg-color4">
                  <th class="px-4 py-3">Id</th>
                  <th class="px-4 py-3">Avatar/Name</th>
                  <th class="px-4 py-3">Email</th>
                  <th class="px-4 py-3">Mobile Number</th>
                  <th class="px-4 py-3">Role</th>
                  <th class="px-4 py-3">Created_At</th>
                  <th class="px-4 py-3">Action</th>
                </tr>
              </thead>
              <tbody class="bg-color1 divide-y dark:divide-gray-700 dark:bg-gray-800">
                {AdminUsers?.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      class="bg-color3 border-b-color1  hover:bg-color1  text-color2"
                    >
                      <td class="px-4 py-3 text-sm">{index + 1}</td>
                      <td class="px-4 py-3">
                        <div class="flex items-center text-sm">
                          {item?.Avatar ? (
                            <div class="relative hidden w-8 h-8 mr-3 rounded-full md:block">
                              <img
                                class="object-cover w-full h-full rounded-full"
                                // src="https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixlib=rb-1.2.1&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=200&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjE3Nzg0fQ"
                                src={ApiMediaURL + "/" + item?.Avatar}
                                alt=""
                                loading="lazy"
                              />
                              <div
                                class="absolute inset-0 rounded-full shadow-inner"
                                aria-hidden="true"
                              ></div>
                            </div>
                          ) : (
                            <div class="relative hidden w-8 h-8 mr-3 rounded-full md:block">
                              <p class="object-cover w-full h-full rounded-full border-[1px] font-bold flex justify-center place-items-center">
                                {item && item?.firstName?.slice(0, 1)}
                              </p>
                              <div
                                class="absolute inset-0 rounded-full shadow-inner"
                                aria-hidden="true"
                              ></div>
                            </div>
                          )}
                          <div>
                            <p class="font-semibold">
                              {item && item?.firstName}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td class="px-4 py-3 text-sm">{item && item?.email}</td>
                      <td class="px-4 py-3 text-sm">
                        {item && item?.phoneNumber}
                      </td>
                      <td class="px-4 py-3 text-xs ">
                        <span
                          class="cursor-pointer px-2 py-1 !w-[70px] font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-green-100"
                          onClick={() =>
                            dispacth(
                              UpdateUserRole(
                                item?._id,
                                item?.role === "user" ? "admin" : "user"
                              )
                            )
                          }
                        >
                          {" "}
                          {item && item?.role}{" "}
                        </span>
                      </td>
                      <td class="px-4 py-3 text-sm">
                        {item && item?.createdAt?.slice(0, 10)}
                      </td>
                      <td class="px-4 py-3 text-sm">
                        <MdDeleteOutline
                          className="text-[23px] cursor-pointer text-[red]"
                          onClick={() => handleDeleteUser(item?._id)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientTable;
