export const USER_SIGNUP_REQUEST = "USER_SIGNUP_REQUEST";
export const USER_SIGNUP_REQUEST_FAIL = "USER_SIGNUP_REQUEST_FAIL";
export const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS";
export const USER_SIGNUP_FAIL = "USER_SIGNUP_FAIL";

// ------- login user
export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_REQUEST_FAIL = "LOGIN_USER_REQUEST_FAIL";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";

// --- token verify
export const TOKEN_VERIFY_REQUEST = "TOKEN_VERIFY_REQUEST";
export const TOKEN_VERIFY_REQUEST_FAIL = "TOKEN_VERIFY_REQUEST_FAIL";
export const TOKEN_VERIFY_SUCCESS = "TOKEN_VERIFY_SUCCESS";
export const TOKEN_VERIFY_FAIL = "TOKEN_VERIFY_FAIL";

// --- logut user
export const LOGOUT_USER_REQUEST = "LOGOUT_USER_REQUEST";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";

// ---- get all user for admin
export const ADMIN_ALL_USERS_REQUEST = "ADMIN_ALL_USERS_REQUEST";
export const ADMIN_ALL_USERS_REQUEST_FAIL = "ADMIN_ALL_USERS_REQUEST_FAIL";
export const ADMIN_ALL_USERS_SUCCESS = "ADMIN_ALL_USERS_SUCCESS";
export const ADMIN_ALL_USERS_FAIL = "ADMIN_ALL_USERS_FAIL";

// ------------- update user profile
export const UPDATE_USER_PROFILE_REQUEST = "UPDATE_USER_PROFILE_REQUEST";
export const UPDATE_USER_PROFILE_FAIL = "UPDATE_USER_PROFILE_FAIL";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_ERROR = "UPDATE_USER_PROFILE_ERROR";

// ----- update user password
export const UPDATE_USER_PASSWORD_REQUEST = "UPDATE_USER_PASSWORD_REQUEST";
export const UPDATE_USER_PASSWORD_FAIL = "UPDATE_USER_PASSWORD_FAIL";
export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";
export const UPDATE_USER_PASSWORD_ERROR = "UPDATE_USER_PASSWORD_ERROR";

// ---- get user location

export const GET_USER_LOCATION = "GET_USER_LOCATION";

// ------
//  ---- delete user prifle
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_REQUEST_FAIL = "DELETE_USER_REQUEST_FAIL";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

// ===== Forgot Password Send Email
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_REQUEST_FAIL = "FORGOT_PASSWORD_REQUEST_FAIL";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
