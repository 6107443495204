import React from "react";

const Conditions = () => {
  return (
    <div className="max-w-[2000px] 2xl:px-[100px] text-[white] my-[50px] md:px-[50px] px-[10px]">
      <h1 className="text-redColor text-[25px] font-[600] mb-3  w-[100%] my-3">
        Terms of Use Agreement between User and InstaPass
      </h1>
      <p className="my-2">
        Please read this Terms of Use agreement (the “Terms of Use" or “Terms”)
        carefully. These Terms of Use govern the use of InstaPass.shop, or the
        mobile applications “InstaPass” (the “Digital Properties”) of InstaPass,
        Inc. (“InstaPass”), and apply to all users visiting the Digital
        Properties, including using the services and resources available or
        enabled via the Digital Properties (each a “Service” and collectively,
        the “Services”). By clicking on the “I Accept”, “Place Order” or “Sign
        Up” button, completing the registration process, making a purchase,
        listing a ticket for sale, and/or browsing the website or downloading
        InstaPass’s mobile application (the “Application”), YOU ARE ENTERING
        INTO A BINDING CONTRACT, and you represent that (1) you have read,
        understand, and agree to be bound by these Terms of Use, (2) you are of
        legal age to form a binding contract with InstaPass, and (3) you have
        the authority to enter into the Terms of Use personally or on behalf of
        company you have named as the user, and to bind that company to these
        Terms of Use. The term “you” refers to the individual or company, as
        applicable, identified as the user when you registered with a Digital
        Property. If you do not agree to be bound by the Terms of Use, you may
        not access or use any Digital Property or the Services.
        <br />
        <br />
        Please be aware that Section 14 of these Terms of Use, below, contains
        provisions governing how claims are resolved that you and we have
        against each other, including, without limitation, any claims that arose
        or were asserted prior to the effective date of these Terms of Use. IN
        PARTICULAR, IT CONTAINS AN ARBITRATION AGREEMENT WHICH WILL, WITH
        LIMITED EXCEPTIONS, REQUIRE DISPUTES BETWEEN US TO BE SUBMITTED TO
        BINDING AND FINAL ARBITRATION. UNLESS YOU OPT OUT OF THE ARBITRATION
        AGREEMENT, DISPUTES BETWEEN YOU AND INSTAPASS WILL BE RESOLVED SOLELY ON
        AN INDIVIDUAL BASIS AND NOT AS A CLASS ARBITRATION, CLASS ACTION, ANY
        OTHER KIND OF REPRESENTATIVE PROCEEDING, OR BY JURY TRIAL.
        <br />
        <br />
        Your use of, and participation in, certain Services may be subject to
        additional terms (“Supplemental Terms”) and such Supplemental Terms will
        either be listed in the Terms of Use or will be presented to you for
        your acceptance when you sign up to use the supplemental Service. If the
        Terms of Use are inconsistent with the Supplemental Terms, the
        Supplemental Terms shall control with respect to such Service. The Terms
        of Use and any applicable Supplemental Terms are referred to herein as
        the “Terms.”
        <br />
        <br />
        PLEASE NOTE THAT THE TERMS ARE SUBJECT TO CHANGE BY INSTAPASS IN ITS
        SOLE DISCRETION AT ANY TIME. If you do not agree to any change(s), you
        shall stop using the Digital Properties and/or the Services. Otherwise,
        your continued use of the Digital Properties and/or Services constitutes
        your acceptance of such change(s). PLEASE REGULARLY CHECK THE WEBSITE TO
        VIEW THE THEN-CURRENT TERMS.
      </p>
      <div>
        <h1 className="text-redColor text-[25px] font-[600] mb-3  w-[100%] my-3">
          InstaPass Marketplace.
        </h1>
        <p className="my-2">
          1{" "}
          <span className="font-bold mx-1"> InstaPass Ticket Marketplace</span>
          . InstaPass offers an online secondary marketplace that allows Users
          (as defined below) to buy (each, a “Buyer”) and sell (each, a
          “Seller”) tickets and other related items to events (each or together
          (as the context may require), a “Ticket”). As such, our marketplace
          Services are merely designed to act as a marketplace to connect Buyers
          and Sellers, and for transactions listed or taking place via
          InstaPass’s ticket marketplace, InstaPass makes no guarantee with
          respect (a) to the existence, quality, safety or legality of the
          Tickets, (b) the truth or accuracy of a Seller’s listings or content,
          (c) the ability of Sellers to sell Tickets or the ability of Buyers to
          pay for them, or (d) that a Seller or Buyer will complete any
          transaction.
        </p>
        <p className="my-2">
          2 <span className="font-bold mx-1">No Endorsement</span>
          . Unless otherwise expressly stated by InstaPass, the display of any
          Tickets or Sellers on our Digital Properties does not in any way
          imply, suggest or constitute an endorsement by us of those Tickets or
          that Seller, or any sponsorship of us by such Seller, or any other
          affiliation between such Seller and us.
        </p>
        <p className="my-2">
          3 <span className="font-bold mx-1">Tickets</span>. Sellers set the
          Ticket prices/value (the “Ticket Price”) and the Ticket Prices may be
          set at, above or below the original price of the Ticket. Unless
          otherwise noted on a Ticket, on the listing or on a confirmation page
          or email, Tickets may be transferable, even if another person’s name
          appears on the face of the Ticket. Some Sellers may offer Tickets for
          sale, even though they may not have them in their possession at the
          time of their listing or completion of sale. Regardless, it is still
          expected that all Sellers are obligated to deliver the Tickets to you
          prior to the event, and a failure to provide you the Tickets prior to
          the Event will be subject to the Buyer Guarantee. For further
          information regarding tickets that are not in the possession of the
          Seller at the time of sale, please see Section 5 regarding Speculative
          Ticketing. All prices for Tickets are in United States dollars unless
          noted otherwise.
        </p>
        <p className="my-2">
          4 <span className="font-bold mx-1">Buying Tickets</span>. Sellers set
          the . Buyers are solely responsible for completing their transactions
          in their purchase of Tickets, including the payment of applicable
          fees. By committing to purchase a Ticket, you are entering into a
          binding contract with the Seller to purchase the applicable Ticket.
          All sales are final, and except for canceled events, you will not
          receive a refund for any completed purchase. You cannot change or
          cancel orders after the sale is complete.
          <p className="my-2 px-2">
            a <span className="font-bold mx-1">Agreement</span> . You agree to
            supply Seller and InstaPass with the appropriate information
            necessary for delivery of tickets, including, but not limited to,
            email address and physical address (if applicable). If you provide
            incorrect information for the delivery of Tickets, or you are
            unavailable to receive the tickets when they are delivered, you will
            not be eligible for a refund or Buyer Guarantee (referenced below).
          </p>
          <p className="my-2 px-2">
            b{" "}
            <span className="font-bold mx-1">
              Number of Tickets or “Ticket Limits”
            </span>
            . In an effort to give all interested customers an opportunity to
            purchase Tickets to an event or other items via the Service and to
            discourage unfair Ticket buying practices, our clients often set
            limits on the number of seating and/or parking rights, or tickets a
            single customer may purchase. You will be advised of any such limits
            during the purchase process, or by a system limitation on the number
            of Tickets you may purchase during your online session. Tickets
            purchased for an event during multiple online sessions via the
            Service may be totaled to ensure that any limitations imposed by the
            InstaPass client is not exceeded. Each InstaPass account must be
            linked to a unique individual, and must contain valid and verifiable
            information. Multiple accounts may not be used to circumvent or
            exceed published ticket limits. If you exceed or attempt to exceed
            the posted ticket limits for a particular event, InstaPass may
            cancel, without notice, any or all Ticket purchase transactions made
            by you for such event and return the Tickets to our client, in
            addition to prohibiting your Ticket purchasing abilities for the
            event.
          </p>
          <p className="my-2 px-2">
            c{" "}
            <span className="font-bold mx-1">Use of Ticket Bot Technology</span>
            . You may not attempt to conceal your identity by using multiple
            Internet Protocol addresses or email addresses, or by any other
            means, to conduct Ticket transactions on the InstaPass online
            marketplace. You may not use ticket bot technology to search for,
            reserve, or purchase Tickets through the InstaPass online
            marketplace. For the avoidance of doubt, this specifically prohibits
            you from using automated ticket purchasing software on the InstaPass
            online marketplace, and prohibits you from circumventing any
            security measure, access control system, or other technological
            control or measure on the InstaPass online marketplace that is used
            to enforce posted event Ticket purchasing limits or to maintain the
            integrity of posted online Ticket purchasing order rules.
          </p>
          <p className="my-2 px-2">
            d <span className="font-bold mx-1">Original Ticket Price</span>
            . We may provide the original ticket price for a Ticket being sold
            on our Digital Properties prior to purchase, in accordance with
            applicable law. As a Buyer, you should understand that (i) we can
            only provide the original price if we have access to the
            information, either from our own records as the primary ticketing
            service provider for the last sale or from the Seller themselves,
            and (ii) in the event we receive this information from the Seller,
            we do not independently verify the truthfulness or accuracy of such
            information
          </p>
          <p className="my-2">
            5 <span className="font-bold"> Buyer Guarantee</span>
            . We want Buyers to be able to buy Tickets with confidence.
            Accordingly, each qualified order is covered by InstaPass’s Buyer
            Guarantee.
          </p>
          <p className="my-2">
            6 <span className="font-bold ">Selling Tickets</span>. If you are a
            Seller that is not part of our Seller Direct Program, please note
            that InstaPass cannot guarantee (a) that every Ticket purchased on
            InstaPass will be eligible for resale on InstaPass (such as, for
            example, a promoter for a tour requests resale to be disabled), or
            (b) that you will receive your Ticket from a Seller enough in
            advance of the event to enable you to resell the Ticket. In
            addition, you may not be able to list a Ticket for resale at your
            desired price (for example, we may impose a Ticket price cap for
            fraud purposes).
          </p>
          <p className="my-2">
            7 <span className="font-bold">InstaPass Properties</span>
            . The Digital Properties, the Services, and the information and
            content available on or in the Digital Properties and the Services
            (as these terms are defined herein) (collectively, the “InstaPass
            Properties.”) are protected by copyright laws throughout the world.
            Unless otherwise specified by InstaPass in a separate license, your
            right to use any InstaPass Properties is subject to the Terms.
            <p className="my-2 px-2">
              a <span>Application License</span>. Subject to your compliance
              with the Terms, InstaPass grants you a limited non-exclusive,
              non-transferable, non-sublicensable, revocable license to
              download, install and use a copy of the Application on a single
              mobile device or computer that you own or control and to run such
              copy of the Application solely for your own personal or internal
              business purposes. Furthermore, with respect to any Application
              accessed through or downloaded from the Apple App Store (an “App
              Store Sourced Application”), you will only use the App Store
              Sourced Application (i) on an Apple-branded product that runs the
              iOS (Apple’s proprietary operating system) and (ii) as permitted
              by the “Usage Rules” set forth in the Apple App Store Terms of
              Service.
            </p>
            <p className="my-2 px-2">
              b <span> Updates</span>. You understand that InstaPass Properties
              are evolving. As a result, InstaPass may require you to accept
              updates to InstaPass Properties that you have installed on your
              computer or mobile device. You acknowledge and agree that
              InstaPass may update InstaPass Properties with or without
              notifying you. You may need to update third-party software from
              time to time in order to use InstaPass Properties.
            </p>
            <p className="my-2 px-2">
              c <span>Certain Restrictions</span>. The rights granted to you in
              the Terms are subject to the following restrictions: (a) you shall
              not license, sell, rent, lease, transfer, assign, reproduce,
              distribute, host or otherwise commercially exploit InstaPass
              Properties or any portion of InstaPass Properties, including the
              Digital Properties, (b) you shall not frame or utilize framing
              techniques to enclose any trademark, logo, or other InstaPass
              Properties (including images, text, page layout or form) of
              InstaPass; (c) you shall not use any metatags or other “hidden
              text” using InstaPass’s name or trademarks; (d) you shall not
              modify, translate, adapt, merge, make derivative works of,
              disassemble, decompile, reverse compile or reverse engineer any
              part of InstaPass Properties except to the extent the foregoing
              restrictions are expressly prohibited by applicable law; (e) you
              shall not use any manual or automated software, devices or other
              processes (including but not limited to spiders, robots, scrapers,
              crawlers, avatars, data mining tools or the like) to “scrape” or
              download data from any web pages contained in or on the Digital
              Properties (except that we grant the operators of public search
              engines revocable permission to use spiders to copy materials from
              the Digital Properties for the sole purpose of and solely to the
              extent necessary for creating publicly available searchable
              indices of the materials, but not caches or archives of such
              materials); (f) you shall not access InstaPass Properties in order
              to build a similar or competitive website, application or service;
              (g) except as expressly stated herein, no part of InstaPass
              Properties may be copied, reproduced, distributed, republished,
              downloaded, displayed, posted or transmitted in any form or by any
              means; and (h) you shall not remove or destroy any copyright
              notices or other proprietary markings contained on or in InstaPass
              Properties. Any future release, update or other addition to
              InstaPass Properties shall be subject to the Terms. You further
              acknowledge and agree that (i) you will not access any Digital
              Property for the purpose of bringing a claim or action against
              InstaPass including, but not limited to patent infringement or ADA
              compliance, and (ii) agree that violation of the foregoing will
              result in a fee of not less than $50,000. InstaPass, its suppliers
              and service providers reserve all rights not granted in the Terms.
              Any unauthorized use of InstaPass Properties (in addition to any
              other right or remedies) shall result in the automatic and
              immediate termination of the licenses granted by InstaPass
              pursuant to the Terms.
            </p>
          </p>
          <p className="my-2">
            8 <span>InstaPass Swaps</span>. Unless otherwise indicated when you
            purchase the Ticket(s), you may return any Ticket(s) eligible for
            return pursuant to this InstaPass Swaps policy to InstaPass in
            exchange for a promotional code equal to the original price you paid
            for your Ticket(s), including all taxes and service fees, at any
            time up to 72 hours prior to the scheduled start time of your event
            (for packages or bundles of Tickets, 72 hours prior to the scheduled
            start time of the first event included in your package or bundle)
            (the “InstaPass Swaps Deadline”), if you agree with the following:
            <p className="my-2 px-2">
              a. You return your Ticket(s) by the InstaPass Swaps Deadline in
              accordance with the instructions provided to you by InstaPass;
            </p>
            <p className="my-2 px-2">
              b. For packages or bundles of Tickets, no partial return of the
              package or bundle is allowed, meaning that (1) only the entire
              package or bundle can be exchanged prior to the InstaPass Swaps
              Deadline and (2) after the InstaPass Swaps Deadline, none of the
              Tickets in the package or bundle may be returned pursuant to the
              InstaPass Swaps policy; InstaPass;
            </p>
            <p className="my-2 px-2">
              c. You will not sell or resell the Ticket(s) and doing so will
              forfeit your eligibility to return your Ticket(s) pursuant to this
              Section 1.8;
            </p>
            <p className="my-2 px-2">
              d. The promotional code (1) shall be issued to you only after your
              Ticket(s) have been received by InstaPass by the InstaPass Swaps
              Deadline; (2) shall expire twelve (12) months from the date of
              issue; and (3) is only eligible for one-time use, meaning that if
              you use the promotional code on a purchase that is less than the
              value of the promotional code, you forfeit the remaining balance
              and may not use the promotional code again;
            </p>
            <p className="my-2 px-2">
              e. No more than one return pursuant to this InstaPass Swaps policy
              is permitted within 30 calendar days; and
            </p>
            <p className="my-2 px-2">
              f. Additional terms may apply to your event as specified at the
              time you purchase your Ticket(s).
            </p>
          </p>
        </p>
      </div>
      <div>
        <h1 className="text-redColor text-[25px] font-[600] mb-3  w-[100%] my-3">
          Registration.
        </h1>
        <p className="my-2">
          1 <span className="mx-1 font-bold">Registering Your Account</span>. In
          order to access certain features of InstaPass Properties you may be
          required to become a Registered User. For purposes of the Terms,
          “Registered User” is an end user of the Services (“User”) who has
          registered an account on the Digital Properties (“Account”), or has a
          valid account on the social networking service (“SNS”) through which
          the User has connected to the Services (each such account, a
          “Third-Party Account
        </p>
        <p className="my-2">
          2 <span className="mx-1 font-bold">Access Through a SNS</span>
          . If you access the InstaPass Properties through a SNS as part of the
          functionality of the Digital Properties, the Application and/or the
          Services, you may link your Account with Third-Party Accounts, by
          allowing InstaPass to access your Third-Party Account, as is permitted
          under the applicable terms and conditions that govern your use of each
          Third-Party Account.
        </p>
        <p className="my-2">
          You represent that you are entitled to disclose your Third-Party
          Account login information to InstaPass and/or grant InstaPass access
          to your Third-Party Account (including, but not limited to, for use
          for the purposes described herein) without breach by you of any of the
          terms and conditions that govern your use of the applicable
          Third-Party Account and without obligating InstaPass to pay any fees
          or making InstaPass subject to any usage limitations imposed by such
          third-party service providers.
          <br />
          PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE
          PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY
          BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS, AND
          INSTAPASS DISCLAIMS ANY LIABILITY FOR PERSONALLY IDENTIFIABLE
          INFORMATION THAT MAY BE PROVIDED TO IT BY SUCH THIRD-PARTY SERVICE
          PROVIDERS IN VIOLATION OF THE PRIVACY SETTINGS THAT YOU HAVE SET IN
          SUCH THIRD-PARTY ACCOUNTS.
        </p>
        <p className="my-2">
          3 <span className="font-bold">Registration Data</span>  In registering
          an account on the Services, you agree to (1) provide true, accurate,
          current and complete information about yourself as prompted by the
          registration form (the “Registration Data”); and (2) maintain and
          promptly update the Registration Data to keep it true, accurate,
          current and complete. You represent that you are (1) at least thirteen
          (13) years old; (2) of legal age to form a binding contract; and (3)
          not a person barred from using InstaPass Properties under the laws of
          the United States, your place of residence or any other applicable
          jurisdiction.
          <br />
          You are responsible for all activities that occur under your Account.
          You may not share your Account or password with anyone, and you agree
          to (1) notify InstaPass immediately of any unauthorized use of your
          password or any other breach of security; and (2) exit from your
          Account at the end of each session. If you provide any information
          that is untrue, inaccurate, not current or incomplete, or InstaPass
          has reasonable grounds to suspect that such information is untrue,
          inaccurate, not current or incomplete, InstaPass has the right to
          suspend or terminate your Account and refuse any and all current or
          future use of InstaPass Properties (or any portion thereof).
          <br />
          You agree not to create an Account using a false identity or
          information, or on behalf of someone other than yourself (and you
          agree that violation of the foregoing will result in a fee of not less
          than $50,000). You agree that you shall not have more than one Account
          per platform or SNS at any given time. InstaPass reserves the right to
          remove or reclaim any usernames at any time and for any reason,
          including but not limited to, claims by a third party that a username
          violates the third party’s rights. You agree not to create an Account
          or use InstaPass Properties if you have been previously removed by
          InstaPass, or if you have been previously banned from any of InstaPass
          Properties.
        </p>
        <p className="my-2">
          4. Necessary Equipment and Software. You must provide all equipment
          and software necessary to connect to InstaPass Properties, including
          but not limited to, a mobile device that is suitable to connect with
          and use InstaPass Properties, in cases where the Services offer a
          mobile component. You are solely responsible for any fees, including
          Internet connection or mobile fees, that you incur when accessing
          InstaPass Properties.
        </p>
      </div>
      <div>
        <h1 className="text-redColor text-[25px] font-[600] mb-3  w-[100%] my-3">
          Ownership.
        </h1>
        <p className="my-2">
          1. InstaPass Properties. You agree that InstaPass and its suppliers
          own all rights, title and interest in InstaPass Properties and all
          content therein or thereon. You agree that you have no right or title
          in or to any such content or the InstaPass Properties. You will not
          remove, alter or obscure any copyright, trademark, service mark or
          other proprietary rights notices incorporated in or accompanying the
          Digital Properties, the Services, or InstaPass Properties.
          <br />2 . Feedback. You agree that submission of any ideas,
          suggestions, documents, and/or proposals to InstaPass through its
          suggestion, feedback, wiki, forum or similar pages (“Feedback”) is at
          your own risk and that InstaPass has no obligations (including without
          limitation obligations of confidentiality) with respect to such
          Feedback. You represent and warrant that you have all rights necessary
          to submit the Feedback. You hereby grant to InstaPass a fully paid,
          royalty-free, perpetual, irrevocable, worldwide, non-exclusive, and
          fully sublicensable right and license to use, reproduce, perform,
          display, distribute, adapt, modify, re-format, create derivative works
          of, and otherwise commercially or non-commercially exploit in any
          manner, any and all Feedback, and to sublicense the foregoing rights,
          in connection with the operation and maintenance of InstaPass
          Properties.
        </p>
      </div>
      <div>
        <h1 className="text-redColor text-[25px] font-[600] mb-3  w-[100%] my-3">
          User Conduct.
        </h1>
        <p className="my-2">
          Unauthorized Use or Access. You agree that you will not, under any
          circumstances:
          <p className="px-2">
            a . Interfere or attempt to interfere with the proper functioning of
            InstaPass Properties or connect to or use InstaPass Properties in
            any way not expressly permitted by the Terms;
            <br />b . Systematically retrieve data or other content from our
            InstaPass Properties to create or compile, directly or indirectly,
            in single or multiple downloads, a collection, compilation,
            database, directory or the like, whether by manual methods, through
            the use of bots, crawlers, spiders, or otherwise; <br />c . Use,
            display, mirror or frame InstaPass Properties, or any individual
            element within InstaPass Properties, InstaPass’s name, any InstaPass
            trademark, logo or other proprietary information, or the layout and
            design of any page or form contained on a page, without InstaPass’s
            express written consent;
            <br />d .Use any unauthorized software that accesses, intercepts,
            “mines” or otherwise collects information from or through InstaPass
            Properties or that is in transit from or to InstaPass Properties,
            including, but not limited to, any software that reads areas of RAM
            or streams of network traffic used by InstaPass Properties;t;
            <br />e .Intercept, examine or otherwise observe any proprietary
            communications protocol used by a client, a server or InstaPass
            Properties, whether through the use of a network analyzer, packet
            sniffer or other device;
            <br />f . Make any automated use of InstaPass Properties, or take
            any action that imposes or may impose (in InstaPass’s sole
            discretion) an unreasonable or disproportionately large load on the
            infrastructure for InstaPass Properties;
            <br />g . Bypass any robot exclusion headers or other measures
            InstaPass takes to restrict access to InstaPass Properties, or use
            any software, technology or device to send content or messages,
            scrape, spider or crawl InstaPass Properties, or harvest or
            manipulate data;
            <br />h . Use, facilitate, create, or maintain any unauthorized
            connection to InstaPass Properties, including, but not limited to:
            (i) any connection to any unauthorized server that emulates, or
            attempts to emulate, any part of InstaPass Properties; or (ii) any
            connection using programs, tools or software not expressly approved
            by InstaPass; j . Reverse engineer, decompile, disassemble, decipher
            or otherwise attempt to derive the source code for any underlying
            software or other intellectual property used to provide InstaPass
            Properties, or to obtain any information from InstaPass Properties;
            <br />i . Reverse engineer, decompile, disassemble, decipher or
            otherwise attempt to derive the source code for any underlying
            software or other intellectual property used to provide InstaPass
            Properties, or to obtain any information from InstaPass Properties;
            <br />l . Upload, post, e-mail, transmit or otherwise make available
            any material that contains software viruses or any other computer
            code, files or programs designed to interrupt, destroy or limit the
            functionality of any computer software or hardware or
            telecommunications equipment; k . Solicit or attempt to solicit
            personal information from other Users of InstaPass Properties;
            <br />
            <span className="px-2">
              1 . Use InstaPass Properties to collect, harvest, transmit,
              distribute, post or submit any information concerning any other
              person or entity, including without limitation, photographs of
              others without their permission, personal contact information or
              credit, debit, calling card or account numbers;
            </span>
            <br />m . Forge any TCP/IP packet header or any part of the header
            information in any e-mail or newsgroup posting, or in any way use
            InstaPass Properties to send altered, deceptive or false
            source-identifying information; or
            <br />n .Upload or transmit (or attempt to upload or to transmit)
            any material that acts as a passive or active information collection
            or transmission mechanism, including, but not limited to, clear
            GIFs, 1x1 pixels, web bugs, cookies or other similar devices
            (sometimes referred to as “spyware,” “passive collection mechanisms”
            or “pcms”).
          </p>
        </p>
      </div>
      <div>
        <h1 className="text-redColor text-[25px] font-[600] mb-3  w-[100%] my-3">
          2. General Use.
          <br />
        </h1>
        In connection with your use of InstaPass Properties, you shall not:
        <br />
        <p className="my-2">a. Harm minors in any way;</p>
        <p className="my-2">
          b. Impersonate any person or entity, including, but not limited to,
          InstaPass personnel, or falsely state or otherwise misrepresent your
          affiliation with a person or entity;
          <br />
          c. Intentionally or unintentionally violate any applicable local,
          state, national or international law or regulation, or any order of a
          court;
          <br />
          d. Register for more than one Account or register for an Account on
          behalf of an individual other than yourself;
          <br />
          e. Stalk or otherwise harass any other User of our InstaPass
          Properties; or
          <br />
          f. Advocate, encourage or assist any third party in doing any of the
          foregoing activities in this section.
        </p>
      </div>
      <div>
        <h1 className="text-redColor text-[25px] font-[600] mb-3  w-[100%] my-3">
          Speculative Ticketing.
          <br />
        </h1>
        <br />
        <p className="my-2">
          For the purpose of these Terms of Use, Speculative Ticketing means the
          sale of tickets for which the Seller has neither possession of the
          actual ticket nor the contractual right to purchase a ticket that is
          materially consistent with the description of the ticket listed for
          sale on the InstaPass Platform (“Speculative Ticketing”). InstaPass
          does not permit Speculative Ticketing for NFL games, nor does
          InstaPass permit Sellers that are not professional Sellers as part of
          the InstaPass Seller Direct program to participate in Speculative
          Ticketing, but InstaPass may permit Speculative Ticketing by
          professional Sellers for other events. Regardless of whether you
          purchased a Ticket or purchased the right to a Ticket by purchasing a
          Speculative Ticketing listing, InstaPass stands behind the Buyer
          Guarantee set forth in Section 1.5.
        </p>
      </div>
      <div>
        <h1 className="text-redColor text-[25px] font-[600] mb-3  w-[100%] my-3">
          App Stores.
          <br />
        </h1>
        <br />
        <p className="my-2">
          You acknowledge and agree that the availability of the Application and
          the Services is dependent on the third party from whom you received
          the Application license, e.g., the Apple App Store or Google Play
          (“App Store”). You acknowledge that the Terms are between you and
          InstaPass and not with the App Store. InstaPass, not the App Store, is
          solely responsible for InstaPass Properties, including the
          Application, the content thereof, maintenance, support services, and
          warranty therefor, and addressing any claims relating thereto (e.g.,
          product liability, legal compliance or intellectual property
          infringement). In order to use the Application, you must have access
          to a wireless network, and you agree to pay all fees associated with
          such access. You also agree to pay all fees (if any) charged by the
          App Store in connection with InstaPass Properties, including the
          Application. You agree to comply with, and your license to use the
          Application is conditioned upon your compliance with, all applicable
          third-party terms of agreement (e.g., the App Store’s terms and
          policies) when using InstaPass Properties, including the Application.
          You acknowledge that the App Store (and its subsidiaries) are
          third-party beneficiaries of the Terms and will have the right to
          enforce them.
        </p>
      </div>
      <div>
        <br />
        <p className="my-2">
          1. <spna className="font-bold">Service Fees</spna>. InstaPass may
          charge fees for buying and/or selling Tickets through our Services, as
          well as delivery or fulfillment fees (collectively, “Service Fees”),
          which is made available to you prior to buying or selling a ticket.
          InstaPass reserves the right at any time to change its Service Fees
          and billing methods. InstaPass may retain Service Fees if you do not
          fulfill your obligations hereunder or if the Event is canceled. You
          must provide InstaPass with a valid credit card (Visa, MasterCard, or
          any other issuer accepted by us) (“Payment Provider”) a condition to
          signing up for the Services (each, a “Payment Method”). Your Payment
          Provider agreement governs your use of the designated credit or debit
          card, and you must refer to that agreement and not the Terms to
          determine your rights and liabilities. By providing InstaPass with
          your credit or debit card number and associated payment information,
          you agree that InstaPass is authorized to immediately invoice your
          Account for all fees and charges due and payable to InstaPass
          hereunder and that no additional notice or consent is required. You
          agree to immediately notify InstaPass of any change in your billing
          address or the credit or debit card used for payment hereunder.
        </p>
        <p className="my-2">
          2. <spna className="font-bold">Payments by Buyers</spna>. As a Buyer
          you agree to pay all fees or charges to your Account in accordance
          with the fees, charges and billing terms in effect at the time a fee
          or charge is due and payable.
        </p>
        <p className="my-2">
          3.{" "}
          <spna className="font-bold">
            User Obligations Regarding Ticket Use
          </spna>
          : Users agree to use purchased tickets for personal use only and to
          comply with all applicable laws, regulations, and event policies.
          Prohibited activities include but are not limited to reselling tickets
          for profit, transferring tickets to unauthorized individuals, or
          engaging in any fraudulent or illegal activities related to ticket
          purchases. InstaPass reserves the right to take appropriate action,
          including account suspension or termination, in the event of
          non-compliance with these obligations.
        </p>
        <div>
          <h1 className="text-redColor text-[25px] font-[600] mb-3  w-[100%] my-3">
            Event Cancellations and Postponement.
            <br />
          </h1>
          <p className="my-2">
            a. Event Cancellation. If an event is canceled and not rescheduled
            (a “Canceled Event”), InstaPass will remove the event and any
            listing related to the event from the Services, and provide Buyers
            with notice of the cancellation and further instructions. Buyers are
            required to retain their Ticket(s) with respect to Canceled Events
            and send them back to InstaPass or to the Seller, as directed by
            InstaPass. A Buyer will receive a refund or, subject to applicable
            state laws, a credit, to be determined in InstaPass's sole
            discretion, once the Buyer has sent back the Ticket(s) in the manner
            and within the timeline given by InstaPass, and the Seller will not
            receive the Ticket Price. Please note that certain states require
            the refund of a Ticket if an event is canceled and InstaPass's
            refund policy is intended at all times to comply with all applicable
            state laws. If you did not receive a refund for a canceled event but
            believe that you are entitled to one under applicable state law,
            please contact InstaPass immediately at hi@InstaPass.com.
            <br />
            .b Event Postponement. If an event is postponed, InstaPass will work
            with Buyers, on a case-by-case basis, to attempt to resolve the
            issues. Refunds or credits will not be issued for postponed events,
            unless they are canceled.
            <br />
            c. Other Event Changes. InstaPass is not responsible for any other
            event changes, such as partial performances, venue, line-up or time
            changes. No refunds or credits will be issued under such
            circumstances.
          </p>
          <p className="my-2">
            5. <span className="font-bold">Taxes</span>. InstaPass collects and
            remits Sales Tax on secondary sales in the following jurisdictions:
            Georgia, Idaho, Louisiana, Minnesota, North Carolina, North Dakota,
            Tennessee, Texas, West Virginia, Wisconsin, Wyoming and Washington
            D.C. InstaPass may collect and remit Sales Tax in additional
            jurisdictions in accordance with applicable regulatory and
            legislative changes. If any Services, or payments for any Services
            or Tickets, under the Terms are subject to Sales Tax in any
            jurisdiction, you will be responsible for the payment of such Sales
            Tax and any related penalties or interest to the relevant tax
            authority, and you will indemnify InstaPass for any liability,
            penalties, interest or expense we may incur in connection with such
            Sales Taxes. Upon our request, you will provide us with official
            receipts issued by the appropriate taxing authority, or other such
            evidence that you have paid all applicable taxes. For purposes of
            this section, “Sales Tax” shall mean any sales or use tax, and any
            other tax measured by sales proceeds, that InstaPass is permitted to
            pass to its customers, that is the functional equivalent of a sales
            tax where the applicable taxing jurisdiction does not otherwise
            impose a sales or use tax.
          </p>
          <p className="my-2">
            6. <span className="font-bold">Withholding Taxes</span>. You agree
            to make all payments of fees to InstaPass free and clear of, and
            without reduction for, any withholding taxes. Any such taxes imposed
            on payments of fees to InstaPass will be your sole responsibility,
            and you will provide InstaPass with official receipts issued by the
            appropriate taxing authority, or such other evidence as we may
            reasonably request, to establish that such taxes have been paid.
          </p>
          <p className="my-2">
            7. <span className="font-bold">Third Party Payment Provider</span>
            . InstaPass utilizes third-party payment processing services. The
            processing of payments may be subject to terms and conditions of the
            third-party payment processors in addition to these Terms of Use.
            You must notify us in writing within seven (7) days after receiving
            your credit card statement, if you dispute any of our charges on
            that statement or such dispute will be deemed waived. Billing
            disputes should be notified to the following address:
            Hi@InstaPass.com.
          </p>
          <p className="my-2">
            8. <span className="font-bold">Credits</span>
            . InstaPass may offer promo codes, discount credits, or other types
            of credits ("Credits"). In order to utilize the Credit, you need to
            create an account through the Digital Properties and input your
            information. Credits will expire on the date stated in the offer. If
            no expiration date is listed, Credits will expire one year after the
            first day it can be utilized by you. Unless otherwise stated, a
            Credit is for one-time use only. Thus, if the total price of the
            Ticket(s) purchased is/are less than the monetary amount of the
            Credit, the difference cannot be utilized in subsequent purchases.
            Additionally, a Credit may not be copied, reproduced, distributed,
            or published either directly or indirectly in any form or stored in
            data retrieval systems without our prior written approval. InstaPass
            reserves the right to withdraw or deactivate any Credit (other than
            one which has been purchased) for any reason, at any time. In some
            circumstances involving the Buyer Guarantee, you may be eligible to
            select either a refund or a Credit. Upon making a selection between
            either a refund or a Credit, you will not be able to switch your
            choice. Credits may only be redeemed through the Digital Properties,
            and not through any other website or method of communication.
          </p>
          <p className="my-2">
            8. <span className="font-bold">Fees Charged by Banks</span>: Some
            banks and card issuers impose fees for international or cross-border
            transactions. If you have any questions about these fees or the
            exchange rate applied to your booking, please contact your bank or
            card issuer. Our Group of Companies is not associated or responsible
            for any fees relating to varying exchange rates and card issuer
            fees.
          </p>
          <p className="my-2">
            9. <span className="font-bold"> Currency Conversion</span>: Any
            currency conversion rates displayed on our Service are based on
            public sources and current exchange rates, which may vary between
            the time a booking is made and the time of travel. Such rates are
            provided for information purposes only and, while we seek to provide
            information that is correct, our Group of Companies does not
            guarantee the accuracy of such conversion rates because they are not
            within our control.
          </p>
          <p className="my-2">
            9. <span className="font-bold"> Alternative Payment Methods</span>:
            We may partner with providers of alternative payment methods (such
            as consumer finance companies), to provide our travelers with
            alternative payment methods. Our Group of Companies does not endorse
            or recommend any alternative payment provider or their products or
            services. Our Group of Companies is not responsible for the content
            or the acts or omissions of any alternative payment provider. Your
            use of any such provider’s payment method is at your own risk and
            will be governed by such provider’s terms and policies.
          </p>
          <p className="my-2">
            9. <span className="font-bold">Fraud</span>: If a booking or account
            shows signs of fraud, abuse, association with a
            government-sanctioned person or entity, or other suspicious
            activity, we may request extra information from you. If we
            reasonably conclude that a booking or account is associated with
            fraud, abuse, a government-sanctioned person or entity, or
            suspicious activity, we may:
            <br />
            . Cancel any bookings associated with your name, email address, or
            account.
            <br />
            . Close any associated accounts.
            <br />. Take legal action, including seeking to hold you liable for
            any loss.
          </p>
        </div>
        <div>
          <h1 className="text-redColor text-[25px] font-[600] mb-3  w-[100%] my-3">
            Indemnification
            <br />
          </h1>
          <p className="my-2">
            You agree to indemnify and hold InstaPass, its parents,
            subsidiaries, affiliates, officers, employees, agents, partners and
            licensors (collectively, the “InstaPass Parties”) harmless from any
            losses, costs, liabilities and expenses (including reasonable
            attorneys’ fees) relating to or arising out of: (a) as a Seller, the
            content of your listings and any errors, inaccuracies or omissions
            therein; (b) your use of, or inability to use, InstaPass Properties;
            (c) your violation of the Terms; (d) your violation of any rights of
            another party, including any end users of the Services; (e) your
            violation of any applicable laws, rules or regulations; or (f) any
            disputes or claims between you and any other User. InstaPass
            reserves the right, at its own cost, to assume the exclusive defense
            and control of any matter otherwise subject to indemnification by
            you, in which event you will fully cooperate with InstaPass in
            asserting any available defenses. This provision does not require
            you to indemnify any of the InstaPass Parties for any unconscionable
            commercial practice by such party or for such party’s fraud,
            deception, false promise, misrepresentation or concealment,
            suppression or omission of any material fact in connection with the
            Digital Properties or any Services provided hereunder. You agree
            that the provisions in this section will survive any termination of
            your Account, the Terms or your access to InstaPass Properties.
            <br />
            InstaPass reserves the right, at its own cost, to assume the
            exclusive defense and control of any matter otherwise subject to
            indemnification by you, in which event you will fully cooperate with
            InstaPass in asserting any available defenses. This provision does
            not require you to indemnify any of the InstaPass Parties for any
            unconscionable commercial practice by such party or for such party’s
            fraud, deception, false promise, misrepresentation, or concealment,
            suppression, or omission of any material fact in connection with the
            Digital Properties or any Services provided hereunder. You agree
            that the provisions in this section will survive any termination of
            your Account, the Terms, or your access to InstaPass Properties.
          </p>
        </div>
        <div>
          <h1 className="text-redColor text-[25px] font-[600] mb-3  w-[100%] my-3">
            Disclaimer of Warranties and Conditions.
            <br />
          </h1>
          <p className="my-2">
            1. As Is. YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE EXTENT
            PERMITTED BY APPLICABLE LAW, YOUR USE OF INSTAPASS PROPERTIES IS AT
            YOUR SOLE RISK, AND INSTAPASS PROPERTIES ARE PROVIDED ON AN “AS IS”
            AND “AS AVAILABLE” BASIS, WITH ALL FAULTS. INSTAPASS PARTIES
            EXPRESSLY DISCLAIM ALL WARRANTIES, REPRESENTATIONS, AND CONDITIONS
            OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
            TO, THE IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS
            FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT ARISING FROM USE OF
            THE INSTAPASS WEBSITE, APPLICATION OR SERVICES.
            <p className="my-2 px-1">
              a . INSTAPASS PARTIES MAKE NO WARRANTY, REPRESENTATION OR
              CONDITION THAT: (1) INSTAPASS PROPERTIES WILL MEET YOUR
              REQUIREMENTS; (2) YOUR USE OF INSTAPASS PROPERTIES WILL BE
              UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; OR (3) THE RESULTS
              THAT MAY BE OBTAINED FROM USE OF INSTAPASS PROPERTIES WILL BE
              ACCURATE OR RELIABLE.
            </p>
            <p className="my-2 px-1">
              b . THE SERVICES MAY BE SUBJECT TO DELAYS, CANCELLATIONS AND OTHER
              DISRUPTIONS. INSTAPASS MAKES NO WARRANTY, REPRESENTATION OR
              CONDITION WITH RESPECT TO SERVICES, INCLUDING BUT NOT LIMITED TO,
              THE QUALITY, EFFECTIVENESS, REPUTATION AND OTHER CHARACTERISTICS
              OF SERVICES.
            </p>
            <p className="my-2 px-1">
              c. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED
              FROM INSTAPASS OR THROUGH INSTAPASS PROPERTIES WILL CREATE ANY
              WARRANTY NOT EXPRESSLY MADE HEREIN.
            </p>
            <p className="my-2 px-1">
              d. FROM TIME TO TIME, INSTAPASS MAY OFFER NEW “BETA” FEATURES OR
              TOOLS WITH WHICH ITS USERS MAY EXPERIMENT. SUCH FEATURES OR TOOLS
              ARE OFFERED SOLELY FOR EXPERIMENTAL PURPOSES AND WITHOUT ANY
              WARRANTY OF ANY KIND, AND MAY BE MODIFIED OR DISCONTINUED AT
              INSTAPASS’S SOLE DISCRETION. THE PROVISIONS OF THIS SECTION APPLY
              WITH FULL FORCE TO SUCH FEATURES OR TOOLS.
            </p>
          </p>
          <p className="my-2">
            1.{" "}
            <span className="font-bold">
              No Liability for Conduct of Third Parties
            </span>
            . YOU ACKNOWLEDGE AND AGREE THAT INSTAPASS PARTIES ARE NOT LIABLE,
            AND YOU AGREE NOT TO SEEK TO HOLD INSTAPASS PARTIES LIABLE, FOR THE
            CONDUCT OF THIRD PARTIES, INCLUDING OPERATORS OF EXTERNAL SITES, AND
            THAT THE RISK OF INJURY FROM SUCH THIRD PARTIES RESTS ENTIRELY WITH
            YOU.
          </p>
          <p className="my-2">
            2.{" "}
            <span className="font-bold">
              No Liability for Conduct of Other Users
            </span>
            . YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND
            INTERACTIONS WITH OTHER USERS OF INSTAPASS PROPERTIES. YOU
            UNDERSTAND THAT INSTAPASS DOES NOT MAKE ANY ATTEMPT TO VERIFY THE
            STATEMENTS OR LISTINGS OF USERS OF INSTAPASS PROPERTIES. ANY TICKETS
            PURCHASED OR SOLD THROUGH INSTAPASS PROPERTIES ARE AT YOUR OWN RISK,
            AND INSTAPASS MAKES NO REPRESENTATIONS OR WARRANTIES THAT ANY TICKET
            IS VALID, THAT ANY BUYER WILL PAY FOR A TICKET, THAT ANY SELLER WILL
            SELL A VALID TICKET, OR THAT ANY PARTY WILL COMPLETE A TRANSACTION.
          </p>
        </div>
        <div>
          <h1 className="text-redColor text-[25px] font-[600] mb-3  w-[100%] my-3">
            Limitation of Liability.
            <br />
          </h1>
          <p className="my-2">
            1. Disclaimer of Certain Damages. YOU UNDERSTAND AND AGREE THAT IN
            NO EVENT SHALL INSTAPASS PARTIES BE LIABLE FOR ANY LOSS OF PROFITS,
            REVENUE OR DATA, INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL
            DAMAGES ARISING OUT OF OR IN CONNECTION WITH INSTAPASS PROPERTIES,
            OR DAMAGES OR COSTS DUE TO LOSS OF PRODUCTION OR USE, BUSINESS
            INTERRUPTION, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, WHETHER
            OR NOT INSTAPASS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES, ARISING OUT OF OR IN CONNECTION WITH THE TERMS, OR FROM ANY
            COMMUNICATIONS, INTERACTIONS OR MEETINGS WITH OTHER USERS OF
            INSTAPASS PROPERTIES, ON ANY THEORY OF LIABILITY, RESULTING FROM:
            (1) THE USE OR INABILITY TO USE INSTAPASS PROPERTIES; (2) THE COST
            OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES RESULTING FROM ANY
            GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR
            MESSAGES RECEIVED FOR TRANSACTIONS ENTERED INTO THROUGH INSTAPASS
            PROPERTIES; (3) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
            TRANSMISSIONS OR DATA; (4) STATEMENTS OR CONDUCT OF ANY THIRD PARTY
            ON INSTAPASS PROPERTIES; OR (5) ANY OTHER MATTER RELATED TO
            INSTAPASS PROPERTIES, WHETHER BASED ON WARRANTY, COPYRIGHT,
            CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY
            OTHER LEGAL THEORY. THE FOREGOING CAP ON LIABILITY SHALL NOT APPLY
            TO LIABILITY OF A INSTAPASS PARTY FOR (A) DEATH OR PERSONAL INJURY
            CAUSED BY A INSTAPASS PARTY’S NEGLIGENCE; FOR(B) ANY INJURY CAUSED
            BY A INSTAPASS PARTY’S FRAUD OR FRAUDULENT MISREPRESENTATION; OR (C)
            ANY DAMAGES THAT CANNOT BE DISCLAIMED BY APPLICABLE LAW.
          </p>
          <p className="my-2">
            2. Cap on Liability. UNDER NO CIRCUMSTANCES WILL INSTAPASS PARTIES
            BE LIABLE TO YOU FOR MORE THAN THE GREATER OF (A) THE TOTAL AMOUNT
            PAID TO INSTAPASS BY YOU DURING THE ONE-MONTH PERIOD PRIOR TO THE
            ACT, OMISSION OR OCCURRENCE GIVING RISE TO SUCH LIABILITY AND (B)
            THE REMEDY OR PENALTY IMPOSED BY THE STATUTE UNDER WHICH SUCH CLAIM
            ARISES. THE FOREGOING CAP ON LIABILITY SHALL NOT APPLY TO LIABILITY
            OF A INSTAPASS PARTY FOR (A) DEATH OR PERSONAL INJURY CAUSED BY A
            INSTAPASS PARTY’S NEGLIGENCE; OR FOR (B) ANY INJURY CAUSED BY A
            INSTAPASS PARTY’S FRAUD OR FRAUDULENT MISREPRESENTATION.SENTATION;
            OR (C) ANY DAMAGES THAT CANNOT BE DISCLAIMED BY APPLICABLE LAW.
          </p>
          <p className="my-2">
            3. User Information. EXCEPT FOR INSTAPASS’S OBLIGATIONS TO PROTECT
            YOUR PERSONAL DATA AS SET FORTH IN INSTAPASS’S PRIVACY POLICY,
            INSTAPASS ASSUMES NO RESPONSIBILITY FOR THE TIMELINESS, DELETION,
            MIS-DELIVERY OR FAILURE TO STORE ANY PERSONALIZATION SETTINGS.
          </p>
          <p className="my-2">
            4. Basis of the Bargain. THE LIMITATIONS OF DAMAGES SET FORTH ABOVE
            ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN
            INSTAPASS AND YOU.
          </p>
        </div>
        <div>
          <h1 className="text-redColor text-[25px] font-[600] mb-3  w-[100%] my-3">
            Limitation of Liability.
            <br />
          </h1>
          <p className="my-2">
            1. THIS SECTION SETS FORTH THE TERMS AND CONDITIONS PURSUANT TO
            WHICH DISPUTES BETWEEN YOU AND INSTAPASS WILL BE RESOLVED THROUGH
            INDIVIDUAL ARBITRATION (“ARBITRATION AGREEMENT”). PLEASE READ THIS
            SECTION CAREFULLY BECAUSE IT REQUIRES YOU AND INSTAPASS TO ARBITRATE
            DISPUTES AND LIMITS THE MANNER IN WHICH WE CAN SEEK RELIEF FROM EACH
            OTHER. WITH LIMITED EXCEPTIONS, ARBITRATION PRECLUDES YOU AND
            INSTAPASS FROM SUING IN COURT. YOU AND INSTAPASS AGREE THAT
            ARBITRATION WILL BE SOLELY ON AN INDIVIDUAL BASIS. YOU AND INSTAPASS
            ARE WAIVING THE RIGHT TO A CLASS ARBITRATION, CLASS ACTION, OR ANY
            OTHER KIND OF REPRESENTATIVE PROCEEDING. YOU AND INSTAPASS ARE EACH
            WAIVING THE RIGHT TO TRIAL BY A JURY.
          </p>
          <p className="my-2">
            2.{" "}
            <span className="my-1 font-bold">
              Dispute Resolution/Notice Regarding Mandatory Arbitration
            </span>
            . InstaPass is committed to customer satisfaction, so if you have an
            issue, we will try to resolve your concerns. But if we are
            unsuccessful, the process for pursuing claims is explained in this
            section. You and InstaPass agree to give the other an opportunity to
            resolve any Disputes informally by sending us written notice. If you
            assert a Dispute against InstaPass, you will first contact InstaPass
            by sending written notice (“Claimant Notice”) to “InstaPass Legal
            Department: Arbitration Claim Manager,” at 902 Broadway, Floor 10,
            New York, New York 10010, with an email copy to legal@InstaPass.com.
            The Claimant Notice must (i) include your name, address, user name
            (if any), email address you used to set up your InstaPass account
            (if any), and telephone number; (ii) describe the nature and basis
            of the Dispute; and (iii) set forth the specific relief sought. If
            InstaPass asserts a Dispute against you, InstaPass will first
            contact you by sending a written notice (“InstaPass Notice”) to you
            via email to the primary email address associated with your account.
            The InstaPass Notice must (i) include the name of a InstaPass
            contact and the contact’s email address and telephone number; (ii)
            describe the nature and basis of the Dispute; and (iii) set forth
            the specific relief sought.
          </p>
          <p className="my-2">
            3.{" "}
            <span className="my-1 font-bold">Informal Dispute Resolution</span>
            . You and InstaPass agree to give each other an opportunity to
            resolve any Dispute through an informal dispute resolution process
            within sixty (60) days after receipt of a Claimant or InstaPass
            Notice. If we cannot reach an agreement to resolve the Dispute
            within sixty (60) days after email receipt of a Notice, then either
            party shall have the right to submit the Dispute to binding
            arbitration or in small claims court as set forth below. The statute
            of limitations and any filing fee deadlines shall be tolled for
            sixty (60) days from the date that either you or InstaPass receive a
            Claimant or InstaPass Notice so that the parties can engage in this
            informal dispute resolution process.
          </p>
          <p className="my-2">
            4.{" "}
            <span className="my-1 font-bold">
              Applicability of Arbitration Agreement
            </span>
            . Except as specifically stated in this Section, any dispute, claim,
            or controversy between you and InstaPass and/or its subsidiaries,
            affiliates, and/or any of their respective members, officers,
            directors, and employees (all such entities collectively referred to
            herein as the “InstaPass Entities”) arising out of, relating in any
            way to, or in connection with the Terms, the InstaPass website,
            Application or Services or your use of the InstaPass website,
            Application or Services, your personal information, or any aspect of
            your relationship with InstaPass, including any dispute, claim, or
            controversy that arose before you accepted these Terms, regardless
            of whether prior versions thereof required arbitration
            (“Dispute(s)”), shall be resolved exclusively by final, binding
            arbitration; except that: (a) you or InstaPass may bring a
            qualifying claim over a Dispute in a small claims court, (b) you or
            InstaPass may seek equitable relief in court for infringement or
            other misuse of intellectual property (such as trademarks, trade
            dress, domain name, trade secrets, copyrights, and patents), or (c)
            you or InstaPass may seek equitable relief in court for any illegal
            or intentional act affecting the accessibility, functionality, or
            the security of the InstaPass website, Application or Services or
            the general business interests of InstaPass.
          </p>
          <p className="my-2">
            6. <span className="my-1 font-bold">Rules and Forum</span>
            . The arbitration shall be administered by National Arbitration and
            Mediation (“NAM”) in accordance with its Comprehensive Dispute
            Resolution Rules and Procedures (the “NAM Rules”) in effect when the
            arbitration is commenced, except as modified by this Section. The
            NAM Rules are available
            at https://www.namadr.com/resources/rules-fees-forms. The NAM Mass
            Filing Supplemental Dispute Resolution Rules and Procedures (the
            “NAM Mass Filing Rules”) will apply if twenty-five (25) or more
            similar claims are asserted against InstaPass by the same or
            coordinated counsel or are otherwise coordinated (“Mass Filing”).
            Any arbitration hearing will be conducted by videoconference to the
            extent possible, but if the arbitrator determines that a hearing
            should be conducted in person, the place shall be the county where
            you reside or at another agreed upon locale, unless the Batch
            Arbitration process discussed below applies in which case the
            arbitrator will determine the locale.
          </p>
          <p className="my-2">
            7. <span className="my-1 font-bold">Batch Arbitration</span>
            . To increase the efficiency of administration and resolution of any
            such Mass Filing, you and InstaPass also agree that in the event
            that there are one hundred (100) or more individual arbitrations of
            a substantially similar nature filed against InstaPass by or with
            the assistance of the same law firm, group of law firms, or
            organizations, within a ninety (90) day period NAM shall (1)
            administer the arbitration demands in batches of 100 claims per
            batch (plus, to the extent there are less than 100 claims left over
            after the batching described above, a final batch consisting of the
            remaining claims); (2) appoint one arbitrator for each batch; and
            (3) provide for the resolution of each batch as a single
            consolidated arbitration with one set of filing and administrative
            fees due per side per batch, one procedural calendar, one hearing
            (if any) in a place to be determined by the arbitrator, and one
            final award (“Batch Arbitration”). To the extent the parties
            disagree on any issue arising out of relating to the Batch
            Arbitration, the disagreeing party shall advise NAM, and NAM shall
            appoint a sole standing arbitrator to determine the applicability of
            the Batch Arbitration process (“Procedural Arbitrator”). The
            Procedural Arbitrator’s fees shall be paid by InstaPass. This
            provision shall in no way be interpreted as authorizing a class,
            collective and/or mass arbitration or action of any kind, or
            arbitration involving joint or consolidated claims under any
            circumstances, except as expressly set forth in this provision. All
            parties agree that arbitrations are of a “substantially similar
            nature” if they arise out of or relate to the same event or factual
            scenario and raise the same or similar legal issues and seek the
            same or similar relief.
          </p>
          <p className="my-2">
            8. <span className="my-1 font-bold">Fees and Costs</span>
            . You and InstaPass will be required to pay fees as assessed by NAM
            in accordance with the NAM Rules. The parties shall bear their own
            attorneys’ fees and costs in arbitration unless the arbitrator finds
            that either the substance of the Dispute or the relief sought was
            frivolous or was brought for an improper purpose (as measured by the
            standards set forth in Federal Rule of Civil Procedure 11(b)).
          </p>
          <p className="my-2">
            9. <span className="my-1 font-bold">Confidentiality</span>
            . You and InstaPass agree that the existence of the arbitration, all
            materials and documents exchanged during the arbitration
            proceedings, and the arbitration award shall be kept confidential
            and shall not be shared with anyone except the parties’ attorneys,
            accountants, or business advisors, and then subject to the condition
            that they agree to keep all materials and documents exchanged during
            the arbitration proceedings confidential. If you or InstaPass
            submits a dispute to arbitration and the arbitrator orders any
            exchange of information, you and InstaPass agree to cooperate to
            seek from the arbitrator protection for any confidential,
            proprietary, trade secret, or otherwise sensitive information,
            documents, testimony, and/or other materials that might be exchanged
            or the subject of discovery in the arbitration. You and InstaPass
            agree to seek such protection before any such information,
            documents, testimony, and/or materials are exchanged or otherwise
            become the subject of discovery in the arbitration.
          </p>
          <p className="my-2">
            10. <span className="my-1 font-bold">Confidentiality</span>
            Award. The arbitrator’s award will be final and binding. Judgment on
            the award may be entered in any court of competent jurisdiction.
            Notwithstanding any of the foregoing, nothing in these Terms will
            preclude you from bringing issues to the attention of federal,
            state, or local agencies and, if the law allows, they can seek
            relief against us for you.
          </p>
          <p className="my-2">
            11. <span className="my-1 font-bold">Arbitrator</span>
            . The arbitrator will be either a retired judge or an attorney
            licensed to practice law in the state of New York and will be
            selected by the parties from NAM’s roster of consumer dispute
            arbitrators. The arbitrator shall have exclusive authority to
            resolve any Dispute, including, without limitation, disputes arising
            out of or related to the interpretation or application of the
            Arbitration Agreement, including the enforceability, revocability,
            scope, or validity of the Arbitration Agreement or any portion of
            the Arbitration Agreement, except that all Disputes arising out of
            or relating to class action waiver including any claim that it is
            unenforceable, illegal, void or voidable, or that it has been
            breached, shall be decided by the courts as described below in
            Section 15.6 and not by an arbitrator.
          </p>
          <p className="my-2">
            12. <span className="my-1 font-bold"> Jury Trial Waiver</span>
            . YOU AND INSTAPASS HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY
            RIGHTS TO SUE IN COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A
            JURY. You and InstaPass are instead electing that all Disputes shall
            be resolved under this Arbitration Agreement, except as specified in
            this Section. There is no judge or jury in arbitration, and court
            review of an arbitration award is subject to very limited review.
          </p>
          <p className="my-2">
            13. <span className="my-1 font-bold">Class Action Waiver</span>
            . YOU AND INSTAPASS FURTHER AGREE THAT, EXCEPT AS SPECIFIED ABOVE IN
            CONNECTION WITH THE BATCHING PROCESS, EACH OF US MAY BRING CLAIMS
            AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT ON A CLASS,
            REPRESENTATIVE, OR COLLECTIVE BASIS, AND THE PARTIES HEREBY WAIVE
            ALL RIGHTS TO HAVE ANY DISPUTE BE BROUGHT, HEARD, ADMINISTERED,
            RESOLVED, OR ARBITRATED ON A CLASS, COLLECTIVE, REPRESENTATIVE, OR
            MASS ACTION BASIS. ONLY INDIVIDUAL RELIEF IS AVAILABLE, AND DISPUTES
            OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR
            CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER.
            Notwithstanding anything to the contrary in this Arbitration
            Agreement, if a final decision is rendered, not subject to any
            further appeal or recourse, that any provision of this Subsection
            14.13 (“Class Action Waiver”) is invalid or unenforceable as to a
            particular request for relief (such as a request for public
            injunctive relief), you and InstaPass agree that that particular
            request for relief (and only that particular request for relief)
            shall be severed from the arbitration and may be litigated in the
            courts as described below in Section 15.6 after the arbitration is
            completed. This subsection does not prevent you or InstaPass from
            participating in a class-wide settlement of claims.
          </p>
          <p className="my-2">
            14.{" "}
            <span className="my-1 font-bold"> One Year to Assert Claims</span>
            . You and InstaPass agree that regardless of any statute or law to
            the contrary, and except as tolled herein, any Dispute must be filed
            in arbitration or small claims court within one year after such
            claim arose or it will be forever banned.
          </p>
          <p className="my-2">
            15. <span className="my-1 font-bold">30-Day Right to Opt Out</span>
            . You have the right to opt out of this Arbitration Agreement by
            sending written notice of your decision to opt out to the following
            address: InstaPass Legal Department, 902 Broadway, Floor 10, New
            York, NY 10010, within 30 days after first agreeing or becoming
            subject to this Arbitration Agreement. Your notice must include your
            name and address, your InstaPass username (if any), the email
            address you used to set up your InstaPass account (if you have one),
            and an unequivocal statement that you want to opt out of this
            Arbitration Agreement. If you opt out of this Arbitration Agreement,
            all other parts of this Agreement will continue to apply to you.
            Opting out of this Arbitration Agreement has no effect on any other
            arbitration agreements that you may currently have, or may enter in
            the future, with us.
          </p>
          <p className="my-2">
            16. <span className="my-1 font-bold">30-Day Right to Opt Out</span>
            . You have the right to opt out of this Arbitration Agreement by
            sending written notice of your decision to opt out to the following
            address: InstaPass Legal Department, 902 Broadway, Floor 10, New
            York, NY 10010, within 30 days after first agreeing or becoming
            subject to this Arbitration Agreement. Your notice must include your
            name and address, your InstaPass username (if any), the email
            address you used to set up your InstaPass account (if you have one),
            and an unequivocal statement that you want to opt out of this
            Arbitration Agreement. If you opt out of this Arbitration Agreement,
            all other parts of this Agreement will continue to apply to you.
            Opting out of this Arbitration Agreement has no effect on any other
            arbitration agreements that you may currently have, or may enter in
            the future, with us.
          </p>
          <p className="my-2">
            17. <span className="my-1 font-bold">Survival of Agreement</span>
            . This Arbitration Agreement will survive the termination of your
            relationship with InstaPass.
          </p>
          <p className="my-2">
            18. <span className="my-1 font-bold">Modification</span>
            . Notwithstanding any provision in this Agreement to the contrary,
            InstaPass may make modifications, deletions, and additions to this
            Arbitration Agreement from time to time. Changes will be posted
            here, which you should regularly check for the most recent version.
            If you continue to use the Services after the effective date of any
            changes to this Arbitration Agreement, then you are deemed to have
            accepted those revisions. For any such change, you may reject that
            change within thirty (30) days of such change becoming effective by
            writing InstaPass at the following address: InstaPass Legal
            Department, 902 Broadway, Floor 10, New York, NY 10010. Changes to
            this Arbitration Agreement shall not apply to any claim that was
            filed in a legal proceeding against InstaPass or you prior to the
            effective date of the modification. Changes to this Arbitration
            Agreement do not provide you with a new opportunity to opt out of
            the Arbitration Agreement if you have previously agreed to a version
            of these Terms and did not validly opt out of arbitration. If you
            reject any change or update to this Arbitration Agreement, and you
            were bound by an existing agreement to arbitrate Disputes arising
            out of or relating in any way to your access to or use of the
            Services or the Terms, the provisions of this Arbitration Agreement
            as of the date you first accepted the Terms (or accepted any
            subsequent changes to these Terms) remain in full force and effect.
            InstaPass will continue to honor any valid opt outs of the
            Arbitration Agreement that you made to a prior version of these
            Terms.
          </p>
        </div>
        <div>
          <h1 className="text-redColor text-[25px] font-[600] mb-3  w-[100%] my-3">
            General Provisions.
            <br />
          </h1>
          <p className="my-2">
            1. <span className="font-bold">Electronic Communications</span>. The
            communications between you and InstaPass use electronic means,
            whether you visit InstaPass Properties or send InstaPass e-mails, or
            whether InstaPass posts notices on InstaPass Properties or
            communicates with you via e-mail. For contractual purposes, you (1)
            consent to receive communications from InstaPass in an electronic
            form; and (2) agree that all terms and conditions, agreements,
            notices, disclosures, and other communications that InstaPass
            provides to you electronically satisfy any legal requirement that
            such communications would satisfy if it were to be in writing. The
            foregoing does not affect your statutory rights.
          </p>
          <p className="my-2">
            2. <span className="font-bold">Release</span>
            . You hereby release InstaPass Parties and their successors from
            claims, demands, any and all losses, damages, rights, and actions of
            any kind, including personal injuries, death, and property damage
            that is either directly or indirectly related to or arises from the
            purchase, sale, advertising, marketing and promotion of Tickets via
            the InstaPass Properties. If you are a California resident, you
            hereby waive California Civil Code Section 1542, which states, “A
            general release does not extend to claims which the creditor does
            not know or suspect to exist in his favor at the time of executing
            the release, which, if known by him must have materially affected
            his settlement with the debtor.” The foregoing release does not
            apply to any claims, demands, or any losses, damages, rights and
            actions of any kind, including personal injuries, death or property
            damage for any unconscionable commercial practice by a InstaPass
            Party or for such party’s fraud, deception, false, promise,
            misrepresentation or concealment, suppression or omission of any
            material fact in connection with the Digital Properties or any
            Services provided hereunder.
          </p>
          <p className="my-2">
            3. <span className="font-bold">Assignment</span>
            . The Terms, and your rights and obligations hereunder, may not be
            assigned, subcontracted, delegated or otherwise transferred by you
            without InstaPass’s prior written consent, and any attempted
            assignment, subcontract, delegation, or transfer in violation of the
            foregoing will be null and void.
          </p>
          <p className="my-2">
            4. <span className="font-bold">Force Majeure.</span>
             InstaPass shall not be liable for any delay or failure to perform
            resulting from causes outside its reasonable control, including, but
            not limited to, acts of God, war, terrorism, riots, embargos, acts
            of civil or military authorities, fire, floods, accidents, strikes
            or shortages of transportation facilities, fuel, energy, labor or
            materials.
          </p>
          <p className="my-2">
            6. <span className="font-bold">Exclusive Venue</span>
            . To the extent the parties are permitted under this Agreement to
            initiate litigation in a court, both you and InstaPass agree and
            consent that all claims and disputes arising out of or relating to
            the Terms will be litigated exclusively in the state or federal
            courts located in New York County, New York.
          </p>
          <p className="my-2">
            7. <span className="font-bold">Governing Law</span>
            . THE TERMS AND ANY ACTION RELATED THERETO WILL BE GOVERNED AND
            INTERPRETED BY AND UNDER THE LAWS OF THE STATE OF CALIFORNIA,
            CONSISTENT WITH THE FEDERAL ARBITRATION ACT, WITHOUT GIVING EFFECT
            TO ANY PRINCIPLES THAT PROVIDE FOR THE APPLICATION OF THE LAW OF
            ANOTHER JURISDICTION. THE UNITED NATIONS CONVENTION ON CONTRACTS FOR
            THE INTERNATIONAL SALE OF GOODS DOES NOT APPLY TO THESE TERMS.
          </p>
          <p className="my-2">
            8. <span className="font-bold">Notice</span>
            . Where InstaPass requires that you provide an e-mail address, you
            are responsible for providing InstaPass with your most current
            e-mail address. In the event that the last e-mail address you
            provided to InstaPass is not valid, or for any reason is not capable
            of delivering to you any notices required/ permitted by the Terms,
            InstaPass’s dispatch of the e-mail containing such notice will
            nonetheless constitute effective notice. You may give notice to
            InstaPass at the following address: InstaPass Legal Department, 790
            E. Colorado Blvd., Pasadena, CA 91101. Such notice shall be deemed
            given when received by InstaPass by letter delivered by nationally
            recognized overnight delivery service or first-class postage prepaid
            mail at the above address.
          </p>
          <p className="my-2">
            9. <span className="font-bold">Waiver</span>
            . Any waiver or failure to enforce any provision of the Terms on one
            occasion will not be deemed a waiver of any other provision or of
            such provision on any other occasion.
          </p>
          <p className="my-2">
            10. <span className="font-bold">Severability</span>
            . If any portion of this Agreement is held invalid or unenforceable,
            that portion shall be construed in a manner to reflect, as nearly as
            possible, the original intention of the parties, and the remaining
            portions shall remain in full force and effect.
          </p>
          <p className="my-2">
            11. <span className="font-bold"> Sanctions & Export Control</span>
            . You may not use, export, import, or transfer InstaPass Properties
            except as authorized by U.S. law, the laws of the jurisdiction in
            which you obtained InstaPass Properties, and any other applicable
            laws. In particular, but without limitation, InstaPass Properties
            may not be exported or re-exported (a) into any United States
            embargoed countries, (b) to anyone on the U.S. Treasury Department’s
            list of Specially Designated Nationals, or (c) the U.S. Department
            of Commerce’s Denied Person’s List or Entity List. Additionally,
            without limitation, InstaPass Properties may not be used in
            connection with transactions (x) involving anyone on the U.S.
            Treasury Department’s Specially Designated Nationals list or Foreign
            Sanctions Evaders list, or (y) within any c
          </p>
        </div>
      </div>
    </div>
  );
};

export default Conditions;
