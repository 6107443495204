import Home from "../pages/Home";
import About from "../pages/About";
import Index from "../DashBoard/Index";
import Tickets from "../pages/Tickets";
import NotFound from "../pages/NotFound";
import Category from "../pages/Category";
import NewsFeeds from "../pages/NewsFeeds";
import Conditions from "../pages/Conditions";
import UserProfile from "../pages/UserProfile";
import { Routes, Route } from "react-router-dom";
import Login from "../Components/accounts/Login";
import GroupDetails from "../pages/GroupDetails";
// import UserLocation from "../pages/UserLocation";
import Registration from "../pages/Registration";
import PrivicyPolicy from "../pages/PrivicyPolicy";
import NewPaymentPage from "../pages/NewPaymentPage";
import ForgotPassword from "../pages/ForgotPassword";
import DownloadInvoice from "../pages/DownloadInvoice";

export default function RouterComponent({ user }) {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/*" element={<NotFound />} />
            <Route path="/login" element={<Login />} />
            <Route path="/aboutUs" element={<About />} />
            <Route path="/tickets" element={<Tickets />} />
            {/* <Route path="/location" element={<UserLocation />} /> */}
            <Route path="/newsfeed/:id" element={<NewsFeeds />} />
            <Route path="/tickets/:name" element={<Category />} />
            <Route path="/payment" element={<NewPaymentPage />} />
            <Route path="/user/profile" element={<UserProfile />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/privacyPolicy" element={<PrivicyPolicy />} />
            <Route path="/terms&conditions" element={<Conditions />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/tickets/group.details/:id" element={<GroupDetails />} />
            {user?.role === "admin" && <Route path="/admin/dashboard" element={<Index />} />}
            <Route path="/invoice/download/:orderId/:itemId" element={<DownloadInvoice />} />
            <Route path="/tickets/group.details/:id/:loc/:date/:time/:name" element={<GroupDetails />} />
        </Routes>
    );
}
