import "./Login.css";
import { FiLock } from "react-icons/fi";
import Spinner from "../Loading/Spinner";
import { RxCross2 } from "react-icons/rx";
import React, { useEffect, useState } from "react";
import { UserLogin } from "../../redux/actions/User";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { Loading } from "notiflix/build/notiflix-loading-aio";



const Loginbox = ({ isModalLogin = false, setModalShow, setActive }) => {

  const isLoading = useSelector((state) => state.user.isLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");


  const dataSubmit = (e) => {
    e.preventDefault();
    dispatch(UserLogin(email, password, navigate, isModalLogin, setModalShow));
  };

  // loading statement
  useEffect(() => {
    if (isLoading === true) {
      Loading.standard("Loading Plaese Wait", {
        svgColor: "#CD292E",
      });
    } else {
      Loading.remove();
    }
  }, [isLoading]);


  const handelNavigate = () => {
    if (isModalLogin) {
      setActive(2)
    } else {
      navigate('/registration');
    }
  }


  return (
    <div class="login-box">
      {
        isModalLogin &&
        <RxCross2
          onClick={() => setModalShow(false)}
          className="text-[23px] float-right hover:text-[#CD292E] cursor-pointer"
        />
      }
      <h2 className="">Login to your account</h2>
      <form onSubmit={dataSubmit}>
        <div class="user-box">
          <input
            type="email"
            name=""
            required=""
            value={email}
            onChange={(e) => setemail(e.target.value)}
            placeholder="Enter your email address"
            className="mt-3"
          />
        </div>
        <div class="user-box my-3">
          <input
            value={password}
            onChange={(e) => setpassword(e.target.value)}
            type="password"
            name=""
            required=""
            placeholder="Enter your password"
            className="mt-3"
          />
        </div>
        <button
          type="submit"
          className={`w-full border-[1px] border-color2 text-color2 py-3 rounded-md my-3 px-2 
    hover:bg-color4 hover:border-color4
     ${isLoading ? "cursor-not-allowed" : "cursor-pointer"}
    `}
        >
          {
            isLoading ? <Spinner /> : "Login"

          }

        </button>
        <div >
          <p className="text-center text-color5 mt-2 cursor-pointer">
            Dont have an account?{" "}
            <span className="text-redColor" onClick={handelNavigate}>Signup</span>
          </p>
        </div>
        <div className="w-full flex justify-end mt-[20px]">
          <NavLink
            to={"/forgotpassword"}
            className="text-[gray] text-[15px]"
          >
            <p className="flex justify-start place-items-center gap-[5px]">
              Forgot Password
              <span>
                <FiLock />
              </span>
            </p>
          </NavLink>
        </div>
      </form>
    </div>
  )
}

export default Loginbox
