import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const LoginAuth = ({ children }) => {
  const user = useSelector((state) => state.user.user);
  const isAuthanticated = useSelector((state) => state.user.isAuthantication);


  if (Object.keys(user)?.length > 0) {
    if (user?.firstName && isAuthanticated === true) {
        return <Navigate replace to="/user/profile" />;
    }
  } else {
    return <>{children}</>;
  }
};

export default LoginAuth;
