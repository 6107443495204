export const ADMIN_ALL_ORDERS_REQUEST = "ADMIN_ALL_ORDERS_REQUEST";
export const ADMIN_ALL_ORDERS_FAIL = "ADMIN_ALL_ORDERS_FAIL";
export const ADMIN_ALL_ORDERS_SUCCESS = "ADMIN_ALL_ORDERS_SUCCESS";
export const ADMIN_ALL_ORDERS_ERROR = "ADMIN_ALL_ORDERS_ERROR";

// ---------- print order
export const PRINT_ORDERS_REQUEST = "PRINT_ORDERS_REQUEST";
export const PRINT_ORDERS_FAIL = "PRINT_ORDERS_FAIL";
export const PRINT_ORDERS_SUCCESS = "PRINT_ORDERS_SUCCESS";
export const PRINT_ORDERS_ERROR = "PRINT_ORDERS_ERROR";
