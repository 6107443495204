import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useSelector } from "react-redux";
import PaymentChart from "./PaymentChart";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const OrdersChart = () => {


  const orders = useSelector((state) => state.AdminOrders.OrderData);
  const startYear = 2023;
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = startYear; year <= currentYear; year++) {
    years.push(year);
  }

  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [orderCounts, setOrderCounts] = useState(Array(12).fill(0));

  useEffect(() => {
    const filteredOrders = orders?.filter(order => {
      const orderYear = new Date(order?.createdAt)?.getFullYear();
      return orderYear === selectedYear;
    });

    const counts = Array(12).fill(0);
    filteredOrders?.forEach(order => {
      const month = new Date(order?.createdAt)?.getMonth();
      counts[month] += 1;
    });

    setOrderCounts(counts);
  }, [selectedYear, orders]);





  const options = {
    responsive: true,
    plugins: {
      display: Legend,
      position: "bottom",
      title: {
        display: true,
        text: `Orders Chart Month Wise - ${selectedYear}`,
        color: "white",
      },
    },
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Agust",
    "Septemper",
    "October",
    "November",
    "December",
  ];
  const data = {
    labels: labels?.map((item) => item?.slice(0, 3)),
    datasets: [
      {
        label: "Total Order Count",
        // data: [100,300,400,500,600,700,200,800,900,100,50,30],
        data: orderCounts,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

 

  return (
    <div>
      <div className="flex justify-start place-items-center flex-col gap-5 2xl:flex-row my-5">
        <div className=" w-full  max-h-[600px] bg-color3 px-4 rounded-md  py-2">
          <div className="w-[100%] flex justify-between place-items-center">
            <h2 className="text-[30px] ">Order Chart</h2>
            {/* <label>Select Year: </label> */}
            <select
              value={selectedYear}
              onChange={(e) => setSelectedYear(parseInt(e.target.value))}
              className="w-[250px] bg-black text-white py-1 px-3 rounded-md"
            >
              <option value="">Select year</option>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>

          </div>

          <Bar options={options} data={data} />
        </div>

        <div className=" w-full  max-h-[600px] bg-color3 px-4 rounded-md py-2">
          <PaymentChart/>
        </div>
      </div>
    </div>
  );
};

export default OrdersChart;
