import React, { useEffect, useMemo, useState } from "react";
import { Toaster } from "react-hot-toast";
import RouterComponent from "./routes/Route";
import { BrowserRouter } from "react-router-dom";
import LayoutIndex from "./Components/layout/Index";
import { useDispatch, useSelector } from "react-redux";
import { ScrollToTop } from "react-router-scroll-to-top";
import { GetUserLocationFunc, TokenVerify } from "./redux/actions/User";

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [userIPAddress, setUserIPAddress] = useState("");
  const token = localStorage.getItem("instapass_user_token");

  // ---- find the user ip
  const getVisitorIP = async () => {
    try {
      const response = await fetch("https://api.ipify.org");
      const data = await response.text();
      setUserIPAddress(data);
    } catch (error) {
      console.log("Failed to fetch IP", error);
    }
  };

  const getVisitorLocation = async () => {
    try {
      const response = await fetch(`http://ip-api.com/json/${userIPAddress}`);
      const data = await response.json();
      dispatch(GetUserLocationFunc(data));
    } catch (error) {
      console.log("Failed to fetch location", error);
    }
  };

  // ========================= get the user location
  useEffect(() => {
    if (!userIPAddress) {
      getVisitorIP();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(TokenVerify());
    }
    // eslint-disable-next-line
  }, []);

  // --- find user location by ip
  useMemo(() => {
    if (userIPAddress) {
      getVisitorLocation();
    }
    // eslint-disable-next-line
  }, [userIPAddress]);



  return (
    <>
      <BrowserRouter>
        <LayoutIndex>
          <ScrollToTop />
          <Toaster position="top-right" />
          <RouterComponent user={user} />
        </LayoutIndex>
      </BrowserRouter>
    </>
  );
};

export default App;
