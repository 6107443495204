import Lottie from 'lottie-react'
import React from 'react'
import SVGGET from "../Loading/notfound.json";


const NoDataMessag01 = ({value="data"}) => {
  return (
    <div className='flex justify-center place-items-center gap-3 my-4'>
          <Lottie
          animationData={SVGGET}
          loop={true}
          style={{ width: "200px", height: "auto" }}
          className="svges"
        />
       {/* <p className='text-[gray] text-[20px]'>No {value} Found Yet.</p> */}
    </div>
  )
}

export default NoDataMessag01
