import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import { PrintOrder } from '../redux/actions/UserOrder';
import { Loading } from 'notiflix';

const DownloadInvoice = () => {
    const {orderId, itemId} = useParams();
    const dispatch = useDispatch();
    const IsLoading = useSelector((state) => state.order.isloading);

    useEffect(()=>{
      inVoiceDownloader(
        
      )
    },[]);

    const handleDownloadInvoice = ()=>{
      inVoiceDownloader()
    }

    const inVoiceDownloader = ()=>{
      dispatch(
        PrintOrder(
          orderId,
          itemId
        )
      )
    }

    useEffect(()=>{
      if (IsLoading === true) {
        Loading.standard("Loading Plaese Wait", {
          svgColor: "#CD292E",
        });
      } else {
        Loading.remove();
      }
    },[IsLoading])

    
  return (
    <div className='text-white h-[80vh] w-[100%] flex justify-center place-items-center flex-col'>
        <h1 className='text-[25px]'>Download Invoice.</h1>
        <p onClick={handleDownloadInvoice} className='mt-2 text-[16px] text-blue-500 cursor-pointer hover:underline'>{IsLoading ? "Loading..." : "download again"}</p>
        
    </div>
  )
}

export default DownloadInvoice
