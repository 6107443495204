import React from 'react'
import Navebar from './Navebar'
import Footer from './Footer2'

const LayoutIndex = ({children}) => {
  return (
    <>
     <Navebar/>
     {children} 
     <Footer/>
    </>
  )
}

export default LayoutIndex
