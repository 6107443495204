import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { useSelector } from "react-redux";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const PaymentChart = () => {
    const orders = useSelector((state) => state.AdminOrders.OrderData);
    const startYear = 2023;
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = startYear; year <= currentYear; year++) {
        years.push(year);
    }

    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [orderPayments, setOrderPayments] = useState(Array(12).fill(0));

    useEffect(() => {

        const filterOrders = orders?.filter(order => {
            const orderYear = new Date(order?.createdAt)?.getFullYear();
            return orderYear === selectedYear;
        })

        const counts = Array(12).fill(0);
        filterOrders?.forEach(order => {
            const month = new Date(order?.createdAt)?.getMonth();
            counts[month] += order?.totalAmount;
        });
        setOrderPayments(counts);

    }, [selectedYear, orders])





    const Paymentoptions = {
        responsive: true,
        plugins: {
            display: Legend,
            position: "bottom",
            title: {
                display: true,
                text: "Orders Chart Month Wise",
                color: "white",
            },
        },
    };

    const Paymentlabels = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "Agust",
        "Septemper",
        "October",
        "November",
        "December",
    ];
    const Paymentdata = {
        labels: Paymentlabels?.map((item) => item?.slice(0, 3)),
        datasets: [
            {
                label: "Total Amount",
                data: orderPayments,
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
        ],
    };



    return (
        <div>
            <div className="">
                <div className=" w-full  max-h-[600px] bg-color3 px-4 rounded-md  py-2">
                    <div className="w-[100%] flex justify-between place-items-center">
                        <h2 className="text-[30px] ">Payment Chart</h2>
                        {/* <label>Select Year: </label> */}
                        <select
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                            className="w-[250px] bg-black text-white py-1 px-3 rounded-md"
                        >
                            <option value="">Select year</option>
                            {years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>

                    </div>

                    <Bar options={Paymentoptions} data={Paymentdata} />
                </div>

            </div>
        </div>
    )
}

export default PaymentChart
