import { ApiURL } from "../../../setting/GlobleVariable";
import toast from "react-hot-toast";
import {
  GET_ORDERBYID_ADMIN_ERROR,
  GET_ORDERBYID_ADMIN_REQUEST,
  GET_ORDERBYID_ADMIN_REQUEST_FAIL,
  GET_ORDERBYID_ADMIN_SUCCESS,
  GET_USER_ORDER_BYID_ADMIN_ERROR,
  GET_USER_ORDER_BYID_ADMIN_REQUEST,
  GET_USER_ORDER_BYID_ADMIN_REQUEST_FAIL,
  GET_USER_ORDER_BYID_ADMIN_SUCCESS,
  ORDER_STATUS_ADMIN_ERROR,
  ORDER_STATUS_ADMIN_REQUEST,
  ORDER_STATUS_ADMIN_REQUEST_FAIL,
  ORDER_STATUS_ADMIN_SUCCESS,
} from "../../constant/AdminConstant";
import {
  ADMIN_ALL_ORDERS_ERROR,
  ADMIN_ALL_ORDERS_FAIL,
  ADMIN_ALL_ORDERS_REQUEST,
  ADMIN_ALL_ORDERS_SUCCESS,
} from "../../constant/Orders";

export const AdminAllOrdersFunc = () => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_ALL_ORDERS_REQUEST });
    const res = await fetch(`${ApiURL}/order/admin/orders`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("instapass_user_token"),
      },
    });
    dispatch({ type: ADMIN_ALL_ORDERS_FAIL });
    const data = await res.json();
    if (res.status === 500) {
      return console.log("Internal Server Error");
    }
    if (!data || res.status === 400 || res.status === 403) {
      return console.log(data.message);
    }
    if (res.status === 200) {
      dispatch({ type: ADMIN_ALL_ORDERS_SUCCESS, payload: data.orders });
    }
  } catch (error) {
    dispatch({ type: ADMIN_ALL_ORDERS_ERROR });
  }
};

// --- find user orders by id
export const GetUserOrderById = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_USER_ORDER_BYID_ADMIN_REQUEST });
    const res = await fetch(`${ApiURL}/user/userOrder/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("instapass_user_token"),
      },
    });
    dispatch({ type: GET_USER_ORDER_BYID_ADMIN_REQUEST_FAIL });
    const data = await res.json();
    if (res.status === 500) {
      return console.log("Internal Server Error");
    }
    if (!data || res.status === 400 || res.status === 403) {
      return console.log(data.message);
    }
    if (res.status === 200) {
      dispatch({
        type: GET_USER_ORDER_BYID_ADMIN_SUCCESS,
        payload: data.orders,
      });
    }
  } catch (error) {
    dispatch({ type: GET_USER_ORDER_BYID_ADMIN_ERROR });
  }
};

// --- get order details by id
export const GetOrderDeatils = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_ORDERBYID_ADMIN_REQUEST });
    const res = await fetch(`${ApiURL}/order/order/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("instapass_user_token"),
      },
    });
    dispatch({ type: GET_ORDERBYID_ADMIN_REQUEST_FAIL });
    const data = await res.json();
    if (res.status === 500) {
      return console.log("Internal Server Error");
    }
    if (!data || res.status === 400 || res.status === 403) {
      return console.log(data.message);
    }
    if (res.status === 200) {
      dispatch({
        type: GET_ORDERBYID_ADMIN_SUCCESS,
        payload: data.order,
      });
    }
  } catch (error) {
    dispatch({ type: GET_ORDERBYID_ADMIN_ERROR });
  }
};

// === update order
export const UpateOrderStstus = (id, Orderstatus) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_STATUS_ADMIN_REQUEST });
    const res = await fetch(`${ApiURL}/order/updatestatus/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("instapass_user_token"),
      },
      body: JSON.stringify({ Orderstatus }),
    });
    dispatch({ type: ORDER_STATUS_ADMIN_REQUEST_FAIL });
    const data = await res.json();
    if (res.status === 500) {
      return console.log("Internal Server Error");
    }
    if (!data || res.status === 400 || res.status === 403) {
      return toast.error(data.message);
    }
    if (res.status === 200) {
      toast.success(data.message);
      dispatch({
        type: ORDER_STATUS_ADMIN_SUCCESS,
        payload: data.order,
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: ORDER_STATUS_ADMIN_ERROR });
  }
};
