import React, { useState } from "react";
import "./TrendingCard.css";
import { GoDotFill } from "react-icons/go";
import { FaShareAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ShareModal from "../LatestCard/ShareModal";
import { ApiMediaURL } from "../../../setting/GlobleVariable";

function TrendingCard({ item, index }) {
  const [ishover, setIsHover] = useState(false);
  const [Share, setShare] = useState(false);

  const navigate = useNavigate();
  
  const handleNavigation = (eventId, dataToSend, image) => {
    // Navigate to the desired URL with the data
    navigate(`/tickets/group.details/${eventId}`, {
      state: { data: dataToSend, image: image },
    });
  };
  return (
    <>
      <div
        className="trending_card "
        onMouseLeave={() => setIsHover(true)}
        onMouseEnter={() => setIsHover(false)}
      >
        <div className="overlay z-[10]"></div>
        <div
          className={` w-[40px] h-[25px] absolute top-[10px] left-[10px] card_uper_vector1 z-[9999]`}
        >
          <FaShareAlt
            className={`text-black z-40`}
            onClick={() => setShare(!Share)}
          />
        </div>

        <img
          // src={Tc}
          src={`${ApiMediaURL}/${item?.image}`}
          alt=""
          className="!object-cover"
        />
        <div
          className={`TC_footer_btn  ${ishover && "bounce"} `}
          onClick={() =>
            handleNavigation(
              item?.event[0]?.id,
              item?.event,
              `${ApiMediaURL}/${item?.image}`
            )
          }
        >
          <div className="flex justify-center place-items-center gap-[1px] w-full live_box ">
            <GoDotFill className="text-color2" />
            <p className="font-bold text-[15px] ">LIVE</p>
          </div>
          {/* --- content  */}
          <div className="trending_content">
            <h2>
              {item?.event &&
              item?.event[0]?.name &&
              item?.event[0]?.name?.length > 18
                ? `${item?.event[0]?.name?.slice(0, 18)}...`
                : item?.event[0]?.name}
            </h2>
            <p>
              {item && item?.paragarph && item?.paragarph?.length > 160
                ? `${item?.paragarph?.slice(0, 160)}...`
                : item?.paragarph}
              d ...
            </p>
          </div>
        </div>
      </div>

      {Share && (
        <ShareModal
          setShow={setShare}
          item={item?.event && item?.event[0]}
          Share={Share}
        />
      )}
    </>
  );
}

export default TrendingCard;
