import React, { useState } from "react";
import "./styles/navebar.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { data } from "../CategorySection/DataCategory";
import { ApiMediaURL } from "../../setting/GlobleVariable";
import { SearchEvents } from "../../redux/actions/Events";
import DeskTopNavbar from "./Components/DeskTopNavbar";
import MobileNavbar from "./Components/MobileNavbar";
import DeskTopNavbar02 from "./Components/DeskTopNavbar02";

const Navebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [subId, setShowSubId] = useState(1)
  const [showmenu, setshowmenu] = useState(false);
  const user = useSelector((state) => state.user.user);
  const UserAvatar = ApiMediaURL + user?.Avatar;
  const matchedItem = data?.find((item) => item.id === subId);
  const UserLocationdata = useSelector((state) => state.user.userlocation);


  const searchFunction = async () => {
    navigate(`tickets/${search}`);
    setshowmenu(false);
    await dispatch(SearchEvents(search, UserLocationdata));
  };



  return (
    <>
      <DeskTopNavbar02
        user={user}
        data={data}
        subId={subId}
        search={search}
        setSearch={setSearch}
        UserAvatar={UserAvatar}
        matchedItem={matchedItem}
        setshowmenu={setshowmenu}
        setShowSubId={setShowSubId}
        searchFunction={searchFunction}
      />
      <MobileNavbar
        data={data}
        user={user}
        search={search}
        showmenu={showmenu}
        setSearch={setSearch}
        UserAvatar={UserAvatar}
        setshowmenu={setshowmenu}
        searchFunction={searchFunction}
      />
    </>
  );
};

export default Navebar;
